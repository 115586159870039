import { createAction, createReducer } from "@reduxjs/toolkit";

const recoveryShow = createAction("recoveryShow");
const recoveryHide = createAction("recoveryHide");

const recovery = createReducer(true, (builder) => {
  builder.addCase(recoveryShow, (state, action) => true);
  builder.addCase(recoveryHide, (state, action) => false);
});

// FORM AUTH VARIANT
const authVariantSet = createAction<string>("authVariantSet");
const authVariant = createReducer("login", (builder) => {
  builder.addCase(authVariantSet, (state, action) => action.payload);
});

// RECOVERY EMAIL
const recoveryIsShowSet = createAction<boolean>("recoveryIsShowSet");
const recoveryIsShow = createReducer(false, (builder) => {
  builder.addCase(recoveryIsShowSet, (state, action) => action.payload);
});

const recoveredEmailSet = createAction<string>("recoveredEmailSet");
const recoveredEmail = createReducer("", (builder) => {
  builder.addCase(recoveredEmailSet, (state, action) => action.payload);
});

// ACTIVATED EMAIL

const activationIsShowSet = createAction<boolean>("activationIsShowSet");
const activationIsShow = createReducer(true, (builder) => {
  builder.addCase(activationIsShowSet, (state, action) => action.payload);
});

const activatedEmailSet = createAction<string>("activatedEmailSet");
const activatedEmail = createReducer("", (builder) => {
  builder.addCase(activatedEmailSet, (state, action) => action.payload);
});

//
const activatedResendSet = createAction<boolean>("activatedResendSet");
const activatedResend = createReducer(true, (builder) => {
  builder.addCase(activatedResendSet, (state, action) => action.payload);
});

export const actionRecovery = {
  recoveryShow,
  recoveryHide,
  recoveredEmailSet,
  recoveryIsShowSet,
  //
  activatedEmailSet,
  authVariantSet,
  activatedResendSet,
  activationIsShowSet,
};

export const reducerRecovery = {
  recovery,
  recoveredEmail,
  recoveryIsShow,
  //
  activatedEmail,
  authVariant,
  activatedResend,
  activationIsShow,
};
