import React, { FC } from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import icon from "assets/images/icon-checkbox.svg";

export const Checkbox: FC<props> = ({
  id,
  name,
  type = "checkbox",
  label,
  text,
  role,
  onChange,
}) => (
  <Field name={name}>
    {({ field }: any) => (
      <>
        <label className="form-check-group">
          {label && <BSform.Label>{label}</BSform.Label>}
          <input
            className="form-check-input"
            name={name}
            id={id}
            type={type}
            role={role}
            {...field}
            onInput={(e: any) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
          />
          <span className="form-check-icon-frame">
            <img className="form-check-icon-image" src={icon} alt="" />
          </span>
          <span className="form-check-label">{text}</span>
        </label>

        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </>
    )}
  </Field>
);

type props = {
  label?: string;
  name: string;
  id: string;
  type?: "checkbox" | "radio";
  role?: string;
  value?: string;
  text: any;
  onChange?: any;
};
