import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

type props = {
  children: ReactNode,
  className?: string,
  variant?: string,
}

const Headline: FC<props> = (props) => {
  const classes = clsx('headline', {
    'headline_xl': props.variant === 'xl',
    'headline_lg': props.variant === 'lg',
    'headline_md': props.variant === 'md',
    'headline_sm': props.variant === 'sm',
    'headline_xs': props.variant === 'xs'
  }, props.className);

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

export default Headline;
