import React from "react";
import { Form } from "components/form";
import { InputText } from "components/input";
import ButtonText from "elements/button/ButtonText/ButtonText";
import Headline from "components/headline/Headline";
import { Button } from "elements/button";
import EasyTimer from "components/EasyTimer/EasyTimer";
import { action, useDsp, useSlc, RootState } from "app/store";

export const FormActivate = () => {
  const dispatch = useDsp();
  const activationIsShow = useSlc((state: RootState) => state.activationIsShow);
  const activatedResend = useSlc((state: RootState) => state.activatedResend);
  const activatedEmail = useSlc((state: RootState) => state.activatedEmail);

  return (
    <Form className="form mt-3">
      <ButtonText
        size="md"
        onClick={() => dispatch(action.authVariantSet("login"))}
      >
        &lt; Назад
      </ButtonText>
      {activationIsShow && (
        <>
          <Headline className="mt-3" variant="md">
            Активация аккаунта
          </Headline>
          <div className="form__text mt-2">
            <p />
          </div>
          <div className="mt-3">
            <InputText
              label="E-mail"
              name="email"
              placeholder="Введи личный e-mail"
              role="formRecovEmail"
            />
          </div>
          <div className="mt-3">
            <Button className="mx-auto ms-sm-auto me-sm-0" type="submit">
              Отправить
            </Button>
          </div>
        </>
      )}

      {!activationIsShow && (
        <>
          <Headline className=" mt-3" variant="md">
            Проверь почту
          </Headline>
          <div className="form__text mt-2">
            <p>
              Мы отправили тебе письмо на {activatedEmail}
              <br />
              Перейди по ссылке из письма, чтобы завершить регистрацию.
            </p>
          </div>
          <div className="form__text mt-3">
            <p>
              <b>Не можешь найти письмо?</b> Проверь папку "Спам". Также в
              некоторых почтовых сервисах подобные письма автоматически
              направляются в папку "Рассылки". Если там пусто, отправь письмо
              ещё раз или{" "}
              <button
                type="button"
                className="btn-reset"
                onClick={() => dispatch(action.mFeedbackShow())}
              >
                свяжись&nbsp;с&nbsp;нами.
              </button>
            </p>
            <p>
              <b>Обрати внимание</b>: некоторые письма могут прийти с задержкой
              до 30 минут.
            </p>
          </div>
          <div className="mt-4">
            {!activatedResend && (
              <Button
                className="mx-auto ms-sm-auto me-sm-0"
                type="button"
                disabled
              >
                Отправить ещё раз через&nbsp;
                <EasyTimer
                  timeType={["minutes", "seconds"]}
                  startValue={30}
                  onComplete={() => {
                    dispatch(action.activatedResendSet(true));
                  }}
                />
              </Button>
            )}
            {activatedResend && (
              <Button
                className="mx-auto ms-sm-auto me-sm-0"
                type="button"
                onClick={() => dispatch(action.activationIsShowSet(true))}
              >
                Отправить письмо
              </Button>
            )}
          </div>
        </>
      )}
    </Form>
  );
};
