import React, { FC } from "react";
import { ModalBasic } from "components/modal/";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperWishAccept } from "app/wrappers";

export const ModalWishAccept: FC = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishAccept);
  return (
    <ModalBasic
      show={!!uuid}
      onHide={() => dispatch(action.mWishAcceptSet(""))}
      title={<span>Изменился статус исполнения виша</span>}
      bodyClassName="modal-body--accept"
    >
      <WrapperWishAccept />
    </ModalBasic>
  );
};
