import React from "react";
import { action, useDsp } from "app/store";
import { Button } from "components/button";
import ImageEmpty from "assets/images/image-404-empty@2x.png";
import ImageEmptyMob from "assets/images/image-404-empty-mob@2x.jpg";

export const Page404 = () => {
  const dispatch = useDsp();
  return (
    <section className="empty-notice bg-decoration" role="section404">
      <div className="bg-decoration__wrapper" />
      <div className="empty-notice__container">
        <picture className="empty-notice__image">
          <source srcSet={ImageEmptyMob} media="(max-width: 575px)" />
          <source srcSet={ImageEmpty} />
          <img src={ImageEmpty} alt="" />
        </picture>

        <div className="empty-notice__data">
          <h2 className="empty-notice__title">Упс! Страница не найдена</h2>
          <p className="empty-notice__subtitle">
            Что-то пошло не так.
            <br />
            Обнови страницу или напиши нам на почту.
          </p>
        </div>
        <div className="empty-notice__buttons">
          <Button
            type="button"
            onClick={() => dispatch(action.mFeedbackShow())}
          >
            Обратная связь
          </Button>
        </div>
      </div>
    </section>
  );
};
