import { urls } from "app/constants";
import { useApi } from "./index";
// import { dtoFormikServerErr, dtoProfile } from "app/utils/dto";

export const useApiMyWish = (uuid: string, reload: number) =>
  useApi({ url: urls.wishMy(uuid), method: "get", reload });

export const useApiUserWish = (uuid: string) =>
  useApi({ url: urls.wishUser(uuid), method: "get" });

export const useApiWishList = () =>
  useApi({ url: urls.wishList, method: "get" });

export const useApiMyWishes = () =>
  useApi({ url: urls.profileWishes, method: "get" });
