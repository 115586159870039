import React, { FC } from 'react';
import clsx from 'clsx';

type props = {
  className?: string,
  text?: string,
}

const CardHashtag: FC<props> = (props) => {
  const classes = clsx('card--hashtag', props.className);

  return (
    <span className={classes}>{props.text}</span>
  )
}

export default CardHashtag;
