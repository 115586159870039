import { urls } from "app/constants";
import { dtoRecovery } from "app/utils/dto";
import { axiosBase } from "./_api";
import { path } from "ramda";

export const apiRecovery = (
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosBase({
    url: urls.recovery,
    method: "post",
    data: dtoRecovery(params),
  })
    .then(success)
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        // console.log("API REG ERROR 400", error);
        let fieldErr: any = path(["response", "data", "errors"], error) || {};
        let codeErr: any = path(["response", "data", "code"], error) || "";
        let detailErr: any = path(["response", "data", "details"], error) || {};

        if (codeErr === "invalid_form") {
          if (fieldErr.email) {
            fieldErr.email = fieldErr.email.map((e: string) => {
              if (e === "does_not_exist") return "Email не зарегистрирован";
              return "n";
            });
          }
        }

        if (codeErr === "email_not_approve") {
          fieldErr.email = ["Активируй аккаунт перейдя по ссылке в письме"];
        }

        if (codeErr === "time_limit_expired") {
          fieldErr.email = [
            `Количество секунд до следующей отправки запроса ${detailErr.time} `,
          ];
        }
        if (codeErr === "number_of_attempts_exceeded") {
          fieldErr.email = [`Количество запросов исчерпано. Попробуй завтра`];
        }
        setErrors(fieldErr);
      }
      return unsuccess(error);
    });
};
