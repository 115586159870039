import React, { useEffect } from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { apiWishApprove, apiProfileWishes } from "app/api";
import { useApiMyWish } from "app/hooks";
import { Button } from "components/button";
import { useNavigate } from "react-router-dom";
import { path } from "ramda";
import avatarDef from "assets/images/avatar-default-big.svg";

export const WrapperWishAccept = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishAccept);
  const wishData: any = useApiMyWish(uuid, 0).data;

  console.log("WISH DATA", wishData);

  const contracts: any = path(["data", "contracts"], wishData) || [];

  console.log("WISH CONTRACTS", contracts);

  const apiWishesSuccess = ({ status, data }: any) => {
    // console.log("API PROFILE WISHES SUCCESS", data);
    if (status === 200 && data) {
      dispatch(action.dataProfileWishesSet(data.results));
      dispatch(action.loaderProfileWishesSet(false));
    }
  };

  const apiApproveSuccess = ({ status }: any) => {
    console.log("API APPROVE SUCCESS", status);
    if (status === 200) {
      dispatch(action.mWishAcceptSet(""));

      dispatch(action.hookReloadSet(Date.now()));

      dispatch(action.loaderProfileWishesSet(true));
      apiProfileWishes(apiWishesSuccess, () => {});
    }
  };

  const apiApproveError = (error: any) => {
    console.log("API APPROVE ERROR", error);
  };

  return (
    <>
      <div className="modal__row">
        <p className="modal-body__subtitle">
          Подтверди, что виш действительно выполнен коллегой или отклони его
        </p>
      </div>
      <div className="modal__row mt-2">
        <div className="modal-accept__contracts">
          {contracts &&
            contracts.map((contract: any, id: number) => {
              console.log("CONTRACT", contract);

              const name = path(["performer", "name"], contract) || "";
              const avatar = path(["performer", "image"], contract) || "";

              return (
                <div className="modal-accept__contract" key={id}>
                  <div className="modal-accept__contract-ava">
                    <img src={avatar ? avatar : avatarDef} alt="" />
                  </div>
                  <div className="modal-accept__contract-name">
                    <>{name}</>
                  </div>

                  {contract && contract.status === "new" && (
                    <Button
                      disabled={true}
                      className="modal-accept__contract-btn"
                    >
                      Виш исполняется
                    </Button>
                  )}

                  {contract && contract.status === "performer_committed" && (
                    <Button
                      className="modal-accept__contract-btn"
                      onClick={() => {
                        apiWishApprove(
                          contract.uuid,
                          apiApproveSuccess,
                          apiApproveError
                        );
                      }}
                    >
                      Принять виш
                    </Button>
                  )}

                  {contract && contract.status === "approved" && (
                    <Button
                      disabled={true}
                      className="modal-accept__contract-btn"
                    >
                      Исполнено
                    </Button>
                  )}
                </div>
              );
            })}
        </div>

        {/* <div className="d-flex">
        <Button
          type="button"
          onClick={() =>
            apiWishDone(uuid, (data: any) => apiSuccsess(data, uuid), apiError)
          }
        >
          Подтвердить
        </Button>
        <Button
          type="button"
          white
          onClick={() => dispatch(action.mWishDoneSet(""))}
        >
          Отменить
        </Button>
      </div> */}
      </div>
    </>
  );
};
