import { urls } from "app/constants";
import { dtoLogin } from "app/utils/dto";
import { axiosBase } from "./_api";

export const apiFeedbackTheme = (success: any, unsuccess: any) => {
  axiosBase({
    url: urls.feedbackThemes,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

// DTO
const dtoFeedback = (params: any) => {
  const obj = {
    email_owner: params.email,
    question: params.message,
    theme: params.theme,
  };

  return obj;
};

export const apiFeedback = (params: any, success: any, unsuccess: any) => {
  axiosBase({
    url: urls.feedback,
    method: "post",
    data: dtoFeedback(params),
  })
    .then(success)
    .catch(unsuccess);
};
