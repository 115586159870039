import React from "react";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { FormProfilePerformFilter } from "components/form";
import { Link } from "react-router-dom";
import WishTile from "components/wishTile/WishTile";
import { LoaderMini } from "components/loader";
import ImageEmpty from "assets/images/image-profile-perform-empty@2x.png";
import ImageMobileEmpty from "assets/images/image-mobile-profile-perform-empty@2x-min.png";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as IconHands } from "assets/images/32pt_hands.svg";

export const SectionProfilePerformers = () => {
  const dispatch = useDsp();

  const loading = useSlc((state: RootState) => state.loaderProfilePrfs);
  const data = useSlc((state: RootState) => state.dataProfilePrfs);
  const filter = useSlc((state: RootState) => state.filterProfilePrfs);
  const user = useSlc((state: RootState) => state.user);

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  if (data.length > 0)
    return (
      <section
        className="profile-wishes profile-wishes--porforms bg-decoration"
        role="sectionProfilePerformers"
      >
        <div className="bg-decoration__wrapper bg-decoration__wrapper_profile" />
        <div className="profile-wishes__wrapper">
          <div className="profile__perform">
            <div className="profile__card-icon">
              <IconHands />
            </div>
            <h2 className="profile__card-title">Исполняю виши</h2>
            <p className="profile__card-subtitle">
              {user.balls === 0 && <>Исполнено мной: 0</>}
              {user.balls > 0 && (
                <>
                  Ты уже исполнил <span>{user.balls} виша</span>
                </>
              )}
            </p>
            <button
              className="btn-reset button-action-forward profile__card-button"
              type="button"
            >
              <Link to="/wishlists">
                <span>Выбрать виш</span>
              </Link>
              <IconArrowRight />
            </button>
          </div>

          <div className="profile-wishes__contaiter">
            <FormProfilePerformFilter />
            <div className="profile-wishes__tile-list">
              {data
                .filter((el: any) => {
                  if (filter.value === "all") return true;
                  if (filter.value === el.contract.status) return true;
                  return false;
                })
                .map((el: any) => {
                  const tags: any = [{ text: el.category.name, type: "" }];

                  if (el.contract.status === "new") {
                    tags.unshift({
                      text: "Исполняется",
                      type: "await",
                    });
                  }

                  if (el.contract.status === "performer_committed") {
                    tags.unshift({
                      text: "Ожидает подтверждения выполнения",
                      type: "await",
                    });
                  }

                  if (el.contract.status === "approved") {
                    tags.unshift({
                      text: "Исполнен",
                      type: "done",
                    });
                  }

                  return (
                    <WishTile
                      key={el.uuid}
                      className="wishlist-paginator__tile"
                      title={el.title}
                      avatar={el.author.image}
                      name={`${el.author.name} `}
                      location={el.author.city.name}
                      tags={tags}
                      hashtags={el.tags}
                      // BUTTONS
                      btnDoneShow={el.contract.status === "new"}
                      btnDetailLink={`/user/wishes/${el.uuid}`}
                      linkToProfile={`/user/${el.author.uuid}`}
                      // ACTIONS
                      onDoneClick={() =>
                        dispatch(action.mWishDoneSet(el.contract.uuid))
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="empty-notice bg-decoration">
      <div className="bg-decoration__wrapper" />
      <div className="empty-notice__container">
        <picture className="empty-notice__image">
          <source
            type="image/png"
            srcSet={ImageMobileEmpty}
            media="(max-width: 575px)"
          />
          <source type="image/png" srcSet={ImageEmpty} />
          <img src={ImageEmpty} alt="" />
        </picture>
        <div className="empty-notice__data">
          <h2 className="empty-notice__title">
            У тебя пока нет исполненных вишей
          </h2>
          <p className="empty-notice__subtitle">
            Ты не выполнил ни одного виша коллеги. <br />
            Перейди в вишлист и выбери кому ты хочешь помочь.
          </p>
        </div>
        <div className="empty-notice__buttons">
          <Link to="/wishlists" className="button">
            <span>Перейти в вишлист</span>
          </Link>
          <Link to="/" className="empty-notice__link">
            <span>На главную</span>
            <IconArrowRight />
          </Link>
        </div>
      </div>
    </section>
  );
};
