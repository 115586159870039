import React, { FC } from "react";
import { clsx } from "clsx";

type props = {
  className: string;
  title: string;
  quantity: number;
};

export const TotalCounter: FC<props> = ({ title, quantity, className }) => (
  <div className={clsx("total-counter", className)}>
    <span className="total-counter__title">{title}</span>
    <span className="total-counter__quantity">{quantity}</span>
  </div>
);
