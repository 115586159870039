import React from "react";
import imagePlug from "assets/images/headline-decor-desktop@2x-min.png";

export const ChatPlugProfile = () => (
  <div className="chat">
    <div className="chat-header" />
    <div className="chat-body">
      <div className="chat-body__plug">
        <img src={imagePlug} alt="" />
        <p>Когда виш будет принят, напиши коллеге и обсуди детали исполнения</p>
      </div>
    </div>
    <div className="chat-footer" />
  </div>
);
