import React, { FC } from "react";
import CategoryTile from "components/categoryTile/CategoryTile";
import Headline from "components/headline/Headline";
import { path } from "ramda";

const data = [
  {
    uuid: "razvitie",
    title: "Развитие",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_rabota_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_rabota.svg`,
    url: "/wishlists/razvitie",
  },
  {
    uuid: "sport",
    title: "Спорт",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_sport_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_sport.svg`,
    url: "/wishlists/sport",
  },
  {
    uuid: "puteshestviya",
    title: "Путешествия",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_puteshestviya_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_puteshestviya.svg`,
    url: "/wishlists/travel",
  },
  {
    uuid: "dobro",
    title: "Добро",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_dobro_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_dobro.svg`,
    url: "/wishlists/good",
  },
  {
    uuid: "razvlecheniya",
    title: "Развлечения",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_razvlecheniya_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_razvlecheniya.svg`,
    url: "/wishlists/entertain",
  },
  {
    uuid: "dom",
    title: "Дом",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_dom_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_dom.svg`,
    url: "/wishlists/home",
  },
  {
    uuid: "pitomtsyi",
    title: "Питомцы",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_pitomcy_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_pitomcy.svg`,
    url: "/wishlists/pet",
  },
  {
    uuid: "tvorchestvo",
    title: "Творчество",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_tvorchestvo_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_tvorchestvo.svg`,
    url: "/wishlists/creation",
  },
  {
    uuid: "drugoe",
    title: "Другое",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_drugoe_v2.svg`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_drugoe.svg`,
    url: "/wishlists/other",
  },
];

const QuickCategories = ({ catalog }: any) => (
  <div className="quick--categories">
    <div className="container">
      <Headline className="headline_lg quick--categories__headline">
        Какие бывают вишлисты
      </Headline>
      <div className="quick--categories__list">
        {data.map((el) => {
          const count: number = path([el.uuid, "total"], catalog) || 0;
          return (
            <CategoryTile
              key={el.uuid}
              title={el.title}
              imageMobile={el.imageMobile}
              imageDesktop={el.imageDesktop}
              url={el.url}
              count={count}
            />
          );
        })}
      </div>
    </div>
  </div>
);

export default QuickCategories;
