import React from "react";
import { useSlc, RootState } from "app/store";
import { Link } from "react-router-dom";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
import { ReactComponent as IconHands } from "assets/images/32pt_hands.svg";
import { ReactComponent as IconGallery } from "assets/images/icon-gallery.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { WrapperProfileAvatar, WrapperProfileUser } from "app/wrappers";

export const SectionProfile = () => {
  const user = useSlc((state: RootState) => state.user);

  return (
    <section className="profile bg-decoration" role="sectionProfile">
      <div className="bg-decoration__wrapper bg-decoration__wrapper_profile" />
      <div className="profile__wrapper">
        <div className="profile__user">
          <div className="row">
            <div className="col-4">
              <WrapperProfileAvatar />
            </div>
            <div className="col-8">
              <div className="profile__user-name">{user.name}</div>
              <p className="profile__user-number">№ {user.number}</p>
            </div>
          </div>
          <div className="row">
            <WrapperProfileUser />
          </div>
        </div>

        <div className="profile__experience">
          <div className="profile__wishes">
            <Link to="/profile/wishes">
              <div className="profile__card-icon">
                <IconStaf />
              </div>
              <h2 className="profile__card-title">Мой вишлист</h2>
              <p className="profile__card-subtitle">
                Твои коллеги готовы помочь!
              </p>
            </Link>
            <Link
              className="btn-reset button-action-forward profile__card-button"
              to="/profile/wishes"
            >
              <span>Создать новый виш</span>
              <IconArrowRight />
            </Link>
          </div>

          <div className="profile__perform">
            <Link to="/profile/performers">
              <div className="profile__card-icon">
                <IconHands />
              </div>
              <h2 className="profile__card-title">Исполняю виши</h2>
              <p className="profile__card-subtitle">
                Помоги коллегам с вишами
                {/* {user.balls === 0 && <>Исполнено мной: 0</>}
                {user.balls > 0 && (
                  <>
                    Ты уже исполнил <span>{user.balls} виша</span>
                  </>
                )} */}
              </p>
            </Link>
            <Link to="/profile/performers">
              <div className="btn-reset button-action-forward profile__card-button">
                <span>Выбрать виш</span> <IconArrowRight />
              </div>
            </Link>
          </div>

          <div className="profile__gallery">
            <div className="profile__gallery-row">
              <div className="profile__gallery-icon">
                <IconGallery />
              </div>
              <div className="profile__gallery-header">
                <h2 className="profile__gallery-title">Моя галерея</h2>
                <p className="profile__gallery-subtitle">Делись фотографиями</p>
              </div>
              <div className="btn-reset button-action-forward profile__gallery-button">
                <Link to="/gallery">
                  <span>Перейти в галерею</span>
                </Link>
                <IconArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
