import React from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import ReCAPTCHA from "react-google-recaptcha";
import clsx from "clsx";

type props = {
  className?: string;
  variant?: string;
  label?: string;
  name: string;
  role?: string;
  onChange?: any;
};

export const Recaptcha = ({
  variant,
  name,
  label,
  className,
  role,
  onChange,
}: props) => (
  <Field name={name}>
    {({ field }: any) => (
      <div className="form-group">
        {label && <BSform.Label>{label}</BSform.Label>}

        <div className="form-control-relative-wrapper">
          <ReCAPTCHA
            sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
            onChange={(event: any) => {
              // console.log("ReCAPTCHA", event);
              const eventObj = {
                target: {
                  name,
                  value: event || "",
                },
              };
              field.onChange(eventObj);
            }}
          />
        </div>

        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </div>
    )}
  </Field>
);
