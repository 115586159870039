import React from "react";
import Headline from "components/headline/Headline";
import WishTile from "components/wishTile/WishTile";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import { action, useDsp } from "app/store";
import CardContent from "components/cardContent/cardContent";
import TextLink from "components/textLink/textLink";
import CardTile from "components/cardTile/cardTile";
import CardIcon from "components/cardIcon/cardIcon";
import { ReactComponent as IconHands } from "assets/images/32pt_hands.svg";
import { path } from "ramda";
import "swiper/css";

type props = {
  birthday: any[];
  performWish: any;
};

const BirthdayWishlist = ({ birthday, performWish }: props) => {
  const dispatch = useDsp();
  let wishList: any = path(["data"], birthday) || [];

  const currDate = new Date();
  let currMonth: any = currDate.getMonth() + 1;
  if (currMonth < 10) currMonth = "0" + currMonth;

  wishList = wishList.filter((wish: any) => {
    // console.log("XXXXXXXXXXXXXXXXXXXXX", wish.author.date_birthday.slice(5, 7));
    // console.log("YYYYYYYYYYYYYYYYYYYYY", currMonth);
    return wish.author.date_birthday.slice(5, 7) === "" + currMonth;
  });

  // console.log("== BIRTHDAY LIST", wishList);

  return (
    <div className="birthday--wishlist">
      <div className="container">
        <Headline className="headline_lg birthday--wishlist__headline">
          Исполни виш коллеги <br />в его день рождения!
        </Headline>
        <Swiper
          className="birthday--wishlist__list"
          modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView="auto"
          slideToClickedSlide
          navigation={{
            prevEl: ".birthday--wishlist__arrow--prev",
            nextEl: ".birthday--wishlist__arrow--next",
          }}
          pagination={{
            clickable: true,
            bulletActiveClass: "active",
            bulletClass: "swiper--dots__item",
            el: ".birthday--wishlist__dots",
            renderBullet(index, className) {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {wishList.slice(0, 10).map((el: any) => (
            <SwiperSlide key={el.uuid} className="wish--tile--slide">
              <WishTile
                key={el.uuid}
                className="wishlist-paginator__tile"
                title={el.title}
                avatar={el.author.image}
                name={`${el.author.name}`}
                location={el.author.city.name}
                tags={[{ text: el.category.name, type: "" }]}
                hashtags={el.tags}
                // BUTTONS
                btnDetailShow
                btnActionShow
                linkToProfile={`/user/${el.author.uuid}`}
                // ACTIONS
                onDetailClick={() => {
                  dispatch(
                    action.mWishDetailsDataSet({
                      title: "Виш коллеги",
                      name: el.title,
                      description: el.description,
                      category: el.category.name,
                      city: el.city.name,
                      maxPerformers: el.max_performer_amount,
                      status: el.status,
                    })
                  );
                  dispatch(action.mWishDetailsShow());
                }}
                onActionClick={() => performWish(el.uuid)}
              />
            </SwiperSlide>
          ))}
          <SwiperSlide className="wish--tile--slide wish--tile--slide_end">
            <CardTile autoheight={false}>
              <CardIcon>
                <IconHands />
              </CardIcon>
              <CardContent
                title="Исполни виш коллеги в день рождения!"
                descr=""
              />

              <TextLink
                className="card--tile__link"
                arrow
                url="/wishlists/birthday"
                weight={500}
              >
                Перейти в доску желаний
              </TextLink>
            </CardTile>
          </SwiperSlide>

          <SwiperNavigation
            className="birthday--wishlist__navigation"
            arrowPrev="birthday--wishlist__arrow--prev"
            arrowNext="birthday--wishlist__arrow--next"
            dots="birthday--wishlist__dots"
          />
        </Swiper>
      </div>
    </div>
  );
};

export default BirthdayWishlist;
