import React from "react";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { useSlc, RootState } from "app/store";
import { SubNav } from "components/nav";
import { WrapperWishPaginator } from "app/wrappers";
import { wishlistLinks } from "app/constants";
import { Scrollbar } from "components/scrollbar/";

export const WishPaginator = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  if (isAuth)
    return (
      <>
        <Scrollbar className="subnav__scroll-x">
          <SubNav links={wishlistLinks} />
        </Scrollbar>
        <WrapperWishPaginator />
      </>
    );
  return <RedirectTo path="/sign-in" />;
};
