import React, { useEffect } from "react";
import { IMaskInput, maskSelector } from "app/utils/imask";
import { Field, ErrorMessage, BSform } from "components/form";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconEdit } from "assets/images/icon-edit.svg";
import { ReactComponent as IconSearch } from "assets/images/icon-search.svg";
import clsx from "clsx";

type props = {
  as?: string;
  className?: string;
  variant?: string;
  label?: string;
  name: string;
  type?: string;
  placeholder: string;
  role?: string;
  autocomplete?: string;
  mask?: string | undefined;
  onChange?: any;
  onBlur?: any;
  disabled?: boolean;
  onEditClick?: any;
};

export const InputText = ({
  as,
  variant,
  name,
  label,
  placeholder,
  className,
  type = "text",
  mask,
  role,
  autocomplete,
  onChange,
  onBlur,
  disabled = false,
  onEditClick,
}: props) => {
  useEffect(() => {
    const element: any = document.querySelector(".form-control-repeater");
    if (element) {
      element.style.height = "2rem";
      element.style.height = element.scrollHeight + "px";
    }
  }, []);

  return (
    <Field name={name}>
      {({ field }: any) => (
        <div className="form-group">
          {label && <BSform.Label>{label}</BSform.Label>}

          {as === "textscroll" && (
            <div className="form-control-relative-wrapper form-control-relative-scroller">
              <Scrollbar>
                <BSform.Control
                  className={clsx(
                    "form-control form-control-repeater",
                    className
                  )}
                  as="textarea"
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  role={role}
                  disabled={disabled}
                  {...field}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(
                      /\n\s*\n\n/g,
                      "\n\n"
                    );
                    e.target.value = e.target.value.replace(/^\n/g, "");
                    e.target.value = e.target.value.replace(/\n\n$/, "\n");
                    field.onChange(e);
                    if (onChange) onChange(e);
                    console.log("TEXT AS TEXTAREA", e);

                    e.target.style.height = "2rem";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  onBlur={(e: any) => {
                    if (onBlur) onBlur(e);
                  }}
                />
              </Scrollbar>

              {variant === "edit" && (
                <button
                  type="button"
                  className={`btn-reset form-control-edit-button ${
                    disabled ? "disabled" : ""
                  }`}
                  onClick={() => onEditClick()}
                >
                  <IconEdit />
                </button>
              )}
            </div>
          )}

          {as === "textarea" && (
            <div className="form-control-relative-wrapper">
              {/* <pre
              className={clsx("form-control form-control-template", className)}
            >
              {field.value}
            </pre> */}
              <BSform.Control
                className={clsx(
                  "form-control form-control-repeater",
                  className
                )}
                as="textarea"
                name={name}
                type={type}
                placeholder={placeholder}
                role={role}
                disabled={disabled}
                {...field}
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/\n\s*\n\n/g, "\n\n");
                  e.target.value = e.target.value.replace(/^\n/g, "");
                  e.target.value = e.target.value.replace(/\n\n$/, "\n");
                  field.onChange(e);
                  if (onChange) onChange(e);
                  console.log("TEXT AS TEXTAREA", e);

                  e.target.style.height = "2rem";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                onBlur={(e: any) => {
                  if (onBlur) onBlur(e);
                }}
              />
              {variant === "edit" && (
                <button
                  type="button"
                  className={`btn-reset form-control-edit-button ${
                    disabled ? "disabled" : ""
                  }`}
                  onClick={() => onEditClick()}
                >
                  <IconEdit />
                </button>
              )}
            </div>
          )}

          {as !== "textarea" && as !== "textscroll" && (
            <div className="form-control-relative-wrapper">
              {!mask && (
                <BSform.Control
                  className={clsx("form-control", className)}
                  as={as}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  role={role}
                  autoComplete={autocomplete}
                  disabled={disabled}
                  {...field}
                  onInput={(e: any) => {
                    field.onChange(e);
                    if (onChange) onChange(e);
                  }}
                  onBlur={(e: any) => {
                    if (onBlur) onBlur(e);
                  }}
                />
              )}

              {mask && (
                <IMaskInput
                  className={clsx("form-control", className)}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  role={role}
                  disabled={disabled}
                  {...maskSelector(mask)}
                  {...field}
                  onInput={(e: any) => {
                    field.onChange(e);
                    if (onChange) onChange(e);
                  }}
                  onBlur={(e: any) => {
                    console.log(e.target.value);
                    if (onBlur) onBlur(e);
                  }}
                />
              )}

              {variant === "edit" && (
                <button
                  type="button"
                  className={`btn-reset form-control-edit-button ${
                    disabled ? "disabled" : ""
                  }`}
                  onClick={() => onEditClick()}
                >
                  <IconEdit />
                </button>
              )}

              {variant === "search" && (
                <div className="form-control-search-icon">
                  <IconSearch />
                </div>
              )}
            </div>
          )}

          <ErrorMessage name={name} component="div">
            {(msg) => <div className="input__error-text">{msg}</div>}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
};
