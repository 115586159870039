import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { Button } from "components/button";
import imageKruzokDesktop from "assets/images/image-kruzok-desktop@2x.jpg";
import imageKruzokMobile from "assets/images/image-kruzok-mobile@2x.jpg";

export const ModalKruzok = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mKruzok)}
      onHide={() => dispatch(action.mKruzokHide())}
      className="modal--intro"
      dialogClassName="modal-dialog--intro"
      contentClassName="modal-content--intro"
      bodyClassName="modal-body--intro"
      backdrop="static"
    >
      <>
        <picture>
          <source srcSet={imageKruzokDesktop} media="(min-width: 769px)" />
          <source srcSet={imageKruzokMobile} media="(min-width: 100px)" />
          <img
            className="swiper-intro__slide-top-image"
            src={imageKruzokDesktop}
            alt=""
          />
        </picture>
        <div className="modal-kruzok__data">
          <p>
            <b>«Кружок»</b> – платформа помощи, поддержки и общения.
          </p>
          <p>
            Помогай коллегам в больших и маленьких делах, создавай свои «кружки»
            по интересам и находи единомышленников!{" "}
          </p>

          <p>
            В Кружке каждый сможет найти партнера для обмена опытом,
            экспертизой, совместного решения задачи или хорошего отдыха.{" "}
          </p>

          <p>
            Создавай виши (желания) и участвуй в исполнении других! Виши могут
            быть самыми разными: про развитие, изучение нового навыка, поиск
            редкой книги, занятие спортом, решение сложной математической
            задачи. И главное – мы помогаем друг другу безвозмездно, то есть
            даром, потому что{" "}
            <span className="green">МегаФон – это больше, чем коллеги!</span>
          </p>

          <p>
            На создание платформы нас вдохновил наш суперсайн, а еще – идея
            кружка по интересам, круговорота добра и даже хоровода.{" "}
            <span className="green">
              МегаФон – это настоящая команда единомышленников, наставников,
              экспертов и друзей, объединенных общими ценностями.{" "}
            </span>{" "}
            В обновленной версии появился новый функционал – 2 рейтинга самых
            активных участников:
          </p>

          <p>
            • Индивидуальный рейтинг – каждый месяц. Каждый месяц мы будем
            поощрять трех лидеров и дарить им подарки
          </p>

          <p>
            • Командный рейтинг – каждый квартал. Чем больше желаний исполнили
            коллеги из одного подразделения, тем больше шансов получить
            командный приз по итогам квартала
          </p>

          <p>
            Кружок – это не только название платформы, но и имя главного героя,
            в мире которого желания исполняются очень быстро, потому что он
            окружен отзывчивыми коллегами!
          </p>
        </div>
      </>
    </ModalBasic>
  );
};
