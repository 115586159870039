import { createAction, createReducer } from "@reduxjs/toolkit";

const mWishDetailsShow = createAction("mWishDetailsShow");
const mWishDetailsHide = createAction("mWishDetailsHide");
const mWishDetailsDataSet = createAction<any>("mWishDetailsDataSet");

const mFeedbackShow = createAction("mFeedbackShow");
const mFeedbackHide = createAction("mFeedbackHide");

const mWishCreateShow = createAction("mWishCreateShow");
const mWishCreateHide = createAction("mWishCreateHide");

const mWishRemoveSet = createAction<string>("mWishRemoveSet");

const mAvaCropShow = createAction("mAvaCropShow");
const mAvaCropHide = createAction("mAvaCropHide");
const mAvaCropSetData = createAction<string>("mAvaCropSetData");

// MODAL WISH DETAIL
const mWishDetails = createReducer(false, (builder) => {
  builder.addCase(mWishDetailsShow, (state, action) => true);
  builder.addCase(mWishDetailsHide, (state, action) => false);
});

const mWishDetailsData = createReducer(
  {
    uuid: "",
    title: "",
    name: "",
    description: "",
    category: "",
    city: "",
    maxPerformers: 0,
    status: "",
    contracts: [],
  },
  (builder) => {
    builder.addCase(mWishDetailsDataSet, (state, action) => action.payload);
  }
);

const mFeedback = createReducer(false, (builder) => {
  builder.addCase(mFeedbackShow, (state, action) => true);
  builder.addCase(mFeedbackHide, (state, action) => false);
});

const mWishCreate = createReducer(false, (builder) => {
  builder.addCase(mWishCreateShow, (state, action) => true);
  builder.addCase(mWishCreateHide, (state, action) => false);
});

const mWishRemove = createReducer("", (builder) => {
  builder.addCase(mWishRemoveSet, (state, action) => action.payload);
});

const mAvaCrop = createReducer(false, (builder) => {
  builder.addCase(mAvaCropShow, (state, action) => true);
  builder.addCase(mAvaCropHide, (state, action) => false);
});

const mAvaCropData = createReducer("", (builder) => {
  builder.addCase(mAvaCropSetData, (state, action) => action.payload);
});

// MODAL INFO
const mInfoShow = createAction("mInfoShow");
const mInfoHide = createAction("mInfoHide");
const mInfoSetData = createAction<any>("mInfoSetData");

const mInfo = createReducer(false, (builder) => {
  builder.addCase(mInfoShow, (state, action) => true);
  builder.addCase(mInfoHide, (state, action) => false);
});

const mInfoData = createReducer({ title: "", message: "" }, (builder) => {
  builder.addCase(mInfoSetData, (state, action) => action.payload);
});

// MODAL PROCESS
const mProcessShow = createAction("mProcessShow");
const mProcessHide = createAction("mProcessHide");
const mProcessSetData = createAction<any>("mProcessSetData");

const mProcess = createReducer(false, (builder) => {
  builder.addCase(mProcessShow, (state, action) => true);
  builder.addCase(mProcessHide, (state, action) => false);
});

const mProcessData = createReducer({ title: "", message: "" }, (builder) => {
  builder.addCase(mProcessSetData, (state, action) => action.payload);
});

// MODAL INTRO
const mIntroShow = createAction("mIntroShow");
const mIntroHide = createAction("mIntroHide");
const mIntro = createReducer(false, (builder) => {
  builder.addCase(mIntroShow, (state, action) => true);
  builder.addCase(mIntroHide, (state, action) => false);
});

// MODAL WISH REFUSE
const mWishRefuseSet = createAction<string>("mWishRefuseSet");
const mWishRefuse = createReducer("", (builder) => {
  builder.addCase(mWishRefuseSet, (state, action) => action.payload);
});

// MODAL WISH DONE
const mWishDoneSet = createAction<string>("mWishDoneSet");
const mWishDone = createReducer("", (builder) => {
  builder.addCase(mWishDoneSet, (state, action) => action.payload);
});

// MODAL WISH ACCEPT
const mWishAcceptSet = createAction<string>("mWishAcceptSet");
const mWishAccept = createReducer("", (builder) => {
  builder.addCase(mWishAcceptSet, (state, action) => action.payload);
});

// MODAL GALLERy
const mGalleryShow = createAction("mGalleryShow");
const mGalleryHide = createAction("mGalleryHide");
const mGallery = createReducer(false, (builder) => {
  builder.addCase(mGalleryShow, (state, action) => true);
  builder.addCase(mGalleryHide, (state, action) => false);
});
const mGalleryViewShow = createAction("mGalleryViewShow");
const mGalleryViewHide = createAction("mGalleryViewHide");
const mGalleryView = createReducer(false, (builder) => {
  builder.addCase(mGalleryViewShow, (state, action) => true);
  builder.addCase(mGalleryViewHide, (state, action) => false);
});

// MODAL KRUZOK
const mKruzokShow = createAction("mKruzokShow");
const mKruzokHide = createAction("mKruzokHide");
const mKruzok = createReducer(false, (builder) => {
  builder.addCase(mKruzokShow, (state, action) => true);
  builder.addCase(mKruzokHide, (state, action) => false);
});

export const actionModal = {
  mWishDetailsShow,
  mWishDetailsHide,
  mWishDetailsDataSet,
  mFeedbackShow,
  mFeedbackHide,
  mWishCreateShow,
  mWishCreateHide,
  mWishRemoveSet,
  mWishRefuseSet,
  mAvaCropShow,
  mAvaCropHide,
  mAvaCropSetData,

  mInfoShow,
  mInfoHide,
  mInfoSetData,

  mProcessShow,
  mProcessHide,
  mProcessSetData,

  mIntroShow,
  mIntroHide,
  mGalleryShow,
  mGalleryHide,
  mGalleryViewShow,
  mGalleryViewHide,
  mWishDoneSet,
  mWishAcceptSet,

  mKruzokShow,
  mKruzokHide,
};

export const reducerModal = {
  mWishDetails,
  mWishDetailsData,
  mFeedback,
  mWishCreate,
  mWishRemove,
  mWishRefuse,
  mAvaCrop,
  mAvaCropData,

  mInfo,
  mInfoData,

  mProcess,
  mProcessData,

  mIntro,
  mGallery,
  mGalleryView,
  mWishDone,
  mWishAccept,

  mKruzok,
};
