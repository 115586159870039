import React from "react";
import { useSlc, RootState } from "app/store";
import { WrapperUserWish } from "app/wrappers";
import RedirectTo from "components/RedirectTo/RedirectTo";

export const UserWish = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  if (isAuth) return <WrapperUserWish />;
  return <RedirectTo path="/sign-in" />;
};
