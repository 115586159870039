import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { ReactComponent as Logo } from "assets/images/logo-site.svg";
import { Button } from "elements/button";
import { profileLinks } from "app/constants";
import logoBrand from "assets/images/logo-brand.png";

export const HeaderMiddle = () => {
  const dispatch = useDsp();
  const currRoute = useLocation().pathname;
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  return (
    <header className="header--middle" role="headerMiddle">
      <div className="header--middle__container container">
        {profileLinks.map((el: any) => el.route).includes(currRoute) && (
          <h2 className="header--middle__title">Личный кабинет</h2>
        )}
        {!profileLinks.map((el: any) => el.route).includes(currRoute) && (
          <div className="header--middle__logo" role="headerMiddleLogo">
            <NavLink className="header--middle__logo--link" to="./">
              <Logo />
            </NavLink>
          </div>
        )}
        {isAuth && (
          <Button
            className="header--middle__action"
            type="button"
            onClick={() => dispatch(action.mWishCreateShow())}
            role="headerMiddleWishCreateButton"
          >
            Создать виш
          </Button>
        )}
        {!isAuth && (
          <div className="header--top__logo">
            <div className="header--top__logo--link">
              <img src={logoBrand} alt="" />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
