import React, { useEffect, useState } from "react";
import { Scrollbar } from "components/scrollbar/";
import avatarDefault from "assets/images/avatar-default.svg";
import { Drafty, Tinode } from "tinode-sdk";
import { path } from "ramda";
import { apiChatUsers, apiNotifyRead } from "app/api";
import iconFile from "assets/images/icon-file.svg";
import iconQuote from "assets/images/icon-arrow-quote.svg";
import iconClose from "assets/images/icon-close.svg";
import iconQuoteIndicator from "assets/images/icon-quote-indicator.svg";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import {
  chatImgExsts,
  chatFileExsts,
  chatImgSize,
  chatFileSize,
} from "app/constants";
import { useUrl, convertHeif } from "libheif-web";
import Resizer from "react-image-file-resizer";
import { Link, useParams } from "react-router-dom";
import { imageScaled, blobToBase64 } from "./helpers";
import { AddSmile, AddMedia } from "./_addButtons";

const baseChatUrl = process.env.REACT_APP_API_CHAT_URL;
const tinodeApiKey = process.env.REACT_APP_CHAT_API_KEY;

const tinodeConfig = {
  appName: "Megafon Chat",
  // host: "chat.coterie.testpromo.site/",
  host: baseChatUrl,
  // apiKey: "AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K",
  apiKey: tinodeApiKey,
  transport: "tcp",
  secure: true,
  persist: true,
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      350,
      350,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const Chat = ({ variant, chatSecret, chatTopic, willConnect }: any) => {
  const dispatch = useDsp();
  const [type, setType]: any = useState("");
  const [messages, setMessages]: any[] = useState([]);
  const [tinode, setTinode]: any = useState(new Tinode(tinodeConfig));
  const [users, setUsers]: any = useState([]);
  const [user, setUser]: any = useState({});
  const [spiner, setSpiner] = useState(true);
  const [quote, setQuote]: any = useState(null);
  const wishId: string = useParams().id || "";

  //
  const apiOk = (data: any, anonList: any) => {
    // console.log("++ API ASC ANON USERS SUCCESS", data);
    anonList.forEach((usr: any) => {
      const key: any = usr.user;
      if (key && !user[key]) user[key] = {};
      user[key].checked = true;
    });
    // console.log("+++ USERS CHECKED", user);
    const usrList: any[] = path(["data"], data) || [];
    usrList.forEach((usr: any) => {
      const key: any = usr.tinode_user_uid;
      if (key) {
        user[key].uuid = `${usr.uuid}`;
        user[key].checked = true;
        user[key].image = usr.image;
        user[key].name = usr.name;
        user[key].fullName = `${usr.name}`;
      }
    });
    // console.log("+++ USERS NAMED", user);
    setUser(user);
  };

  const apiErr = (error: any) => {
    // console.log("++ API ASC ANON USERS ERROR", error);
  };

  useEffect(() => {
    // console.log("+++ CREATE LIST OF ANONYMOUS");
    const anonList =
      users
        .filter((an: any) => {
          const key = an.user;
          if (key && user[key] && user[key].checked) return false;
          return true;
        })
        .slice(0, 90) || [];
    if (anonList.length > 0) {
      // console.log("+++ API ASC ANON USERS", anonList);
      apiChatUsers(anonList, (data: any) => apiOk(data, anonList), apiErr);
    } else {
      // console.log("+++ DONT NEED ASC ANON USERS");
    }
  }, [users]);

  //
  const sendMessage = () => {
    if (type && tinode && tinode.isAuthenticated()) {
      // WITH QUOTE
      if (quote) {
        let msg = Drafty.parse(type);
        const autorLetter = quote.userName[0].toUpperCase();
        const reply = {
          content: Drafty.quote(
            autorLetter,
            quote.userId,
            Drafty.init(quote.message)
          ),
          seq: quote.seq,
        };
        let head = { reply: "" + reply.seq };

        msg = Drafty.append(Drafty.sanitizeEntities(reply.content), msg);
        msg.quote = {
          name: quote.userName,
          message: quote.message,
          seq: quote.seq,
        };
        msg.singleText = Drafty.sanitizeEntities(type);

        msg = tinode.createMessage(chatTopic, msg, false);
        msg.head = Object.assign(msg.head || {}, head);

        tinode.publishMessage(msg).then(() => {
          setType("");
          setQuote(null);
        });

        // NO QUOTE
      } else {
        const msg = tinode.createMessage(chatTopic, type);
        tinode.publishMessage(msg).then(() => {
          setType("");
        });
      }
    }
  };

  const addFile = (event: any) => {
    console.log("++FUNCTION UPLOAD FILE", event);
    const file = event.target.files[0];

    if (event && event.target && event.target.files) {
      //
      const ext = file.name.split(".").slice(-1)[0] || "unknown";
      if (!chatFileExsts.includes(ext)) {
        dispatch(
          action.mInfoSetData({
            title: "Ошибка!",
            message:
              "Загружай файлы .jpg, .jpeg, .png, ,pdf, .word и .excel. Размер до 5 Мегабайт",
          })
        );
        dispatch(action.mInfoShow());
        return false;
      }

      //
      if (file.size > chatFileSize) {
        dispatch(
          action.mInfoSetData({
            title: "Ошибка!",
            message:
              "Загружай файлы .jpg, .jpeg, .png, ,pdf, .word и .excel. Размер до 5 Мегабайт",
          })
        );
        dispatch(action.mInfoShow());
        return false;
      }
      //
      if (file && tinode && tinode.isAuthenticated()) {
        //
        setSpiner(true);
        console.log("file", file);

        const uploader = tinode.getLargeFileHelper();
        const uploadCompletionPromise = uploader.upload(file);
        uploadCompletionPromise.then((result: any) => {
          // console.log("=== FILE UP RESULT", result);
          //
          let msg = null;

          // WITH QUOTE
          if (quote) {
            const autorLetter = quote.userName[0].toUpperCase();
            const reply = {
              content: Drafty.quote(
                autorLetter,
                quote.userId,
                Drafty.init(quote.message)
              ),
              seq: quote.seq,
            };
            let head = { reply: "" + reply.seq };

            msg = Drafty.append(Drafty.sanitizeEntities(reply.content), msg);
            msg.quote = {
              name: quote.userName,
              message: quote.message,
              seq: quote.seq,
            };

            msg.fileName = file.name;
            msg.fileType = "file";
            msg.fileUrl = result;
            msg.fileMime = file.type;

            msg = tinode.createMessage(chatTopic, msg, false);
            msg.head = Object.assign(msg.head || {}, head);

            // NO QUOTE
          } else {
            msg = tinode.createMessage(chatTopic, {
              fileName: file.name,
              fileType: "file",
              fileUrl: result,
              fileMime: file.type,
            });
          }

          console.log(" +++ UPLOAD FILE MESSAGE", msg);
          tinode
            .publishMessage(msg)
            .then((data: any) => {
              // console.log("++ PUBLISH FILE SUCCESS", data);
              setQuote(null);
              setSpiner(false);
            })
            .catch((error: any) => {
              // console.log("++ PUBLISH FILE ERROR", error);
              setSpiner(false);
            });
        });
      }
    }
    return false;
  };

  const addImage = async (event: any) => {
    console.log("++ FUNCTION UPLOAD IMAGE", type);
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      console.log("=== FILE", file);

      let blob: any;
      let name: any;
      let width: any;
      let height: any;
      let size: any;
      let mime: any;

      if (file && tinode && tinode.isAuthenticated()) {
        //
        const ext = file.name.split(".").slice(-1)[0] || "unknown";
        if (!chatImgExsts.includes(ext)) {
          dispatch(
            action.mInfoSetData({
              title: "Ошибка!",
              message:
                "Загружай картинки .jpg, .jpeg, .png. Размер до 5 Мегабайт",
            })
          );
          dispatch(action.mInfoShow());
          return false;
        }

        //
        if (file.size > chatImgSize) {
          dispatch(
            action.mInfoSetData({
              title: "Ошибка!",
              message:
                "Загружай картинки .jpg, .jpeg, .png. Размер до 5 Мегабайт",
            })
          );
          dispatch(action.mInfoShow());
          return false;
        }

        setSpiner(true);

        if (ext === "heic") {
          // PRELOADER
          dispatch(
            action.mProcessSetData({
              title: "Обработка",
              message: "Конвертация HIEC в JPG",
            })
          );
          dispatch(action.mProcessShow());
          // HEIC
          useUrl("assets/scripts/libheif.min.js");
          const imageJPG = await convertHeif(file, "filename.jpg", "image/jpg");
          const imageMin: any = await resizeFile(imageJPG);
          // console.log("+++ HEIC => JPG => RESIZE", imageMin);

          imageScaled(imageMin, 1000, 1000, 100000000, false)
            .then((scaled: any) => {
              console.log("== IMAGESCALED", scaled);
              // url: URL.createObjectURL(scaled.blob),
              blob = scaled.blob;
              name = scaled.name;
              width = scaled.width;
              height = scaled.height;
              size = scaled.blob.size;
              mime = scaled.mime;
              blobToBase64(scaled.blob).then((b64: any) => {
                //
                let msg: any = null;

                // WITH QUOTE
                if (quote) {
                  const autorLetter = quote.userName[0].toUpperCase();
                  const reply = {
                    content: Drafty.quote(
                      autorLetter,
                      quote.userId,
                      Drafty.init(quote.message)
                    ),
                    seq: quote.seq,
                  };
                  let head = { reply: "" + reply.seq };

                  msg = Drafty.insertImage(null, 0, {
                    mime: b64.mime,
                    preview: b64.bits,
                    width,
                    height,
                    filename: name,
                    size: blob.size,
                  });

                  msg = Drafty.append(
                    Drafty.sanitizeEntities(reply.content),
                    msg
                  );

                  msg.quote = {
                    name: quote.userName,
                    message: quote.message,
                    seq: quote.seq,
                  };

                  msg = tinode.createMessage(chatTopic, msg, false);
                  msg.head = Object.assign(msg.head || {}, head);

                  // NO QUOTE
                } else {
                  msg = Drafty.insertImage(null, 0, {
                    mime: b64.mime,
                    // _tempPreview: b64.bits, // This preview will not be serialized.
                    preview: b64.bits, // Serializable preview
                    width,
                    height,
                    filename: name,
                    size: blob.size,
                    // urlPromise: uploadCompletionPromise,
                  });
                  msg = tinode.createMessage(chatTopic, msg, false);
                }
                // Pass data and the uploader to the TinodeWeb.
                // const message = tinode.createMessage(chatTopic, msg);
                tinode
                  .publishMessage(msg)
                  .then((data: any) => {
                    console.log("++SEND IMAGE", msg);
                    console.log("++SEND IMAGE ANSWER", data);
                    setQuote(null);
                    setSpiner(false);
                    dispatch(action.mProcessHide());
                  })
                  .catch((error: any) => {
                    console.log("++SEND IMAGE ERROR", error);
                    setSpiner(false);
                    dispatch(action.mProcessHide());
                  });
              });
            })
            .catch((err: any) => {
              console.log("+++ IMAGE SCALE ERROR", err);
              dispatch(action.mProcessHide());
            });
        } else {
          const imageMin: any = await resizeFile(file);
          imageScaled(imageMin, 1000, 1000, 100000000, false)
            .then((scaled: any) => {
              console.log("== IMAGESCALED", scaled);
              // url: URL.createObjectURL(scaled.blob),
              blob = scaled.blob;
              name = scaled.name;
              width = scaled.width;
              height = scaled.height;
              size = scaled.blob.size;
              mime = scaled.mime;
              blobToBase64(scaled.blob).then((b64: any) => {
                //
                let msg: any = null;

                // WITH QUOTE
                if (quote) {
                  const autorLetter = quote.userName[0].toUpperCase();
                  const reply = {
                    content: Drafty.quote(
                      autorLetter,
                      quote.userId,
                      Drafty.init(quote.message)
                    ),
                    seq: quote.seq,
                  };
                  let head = { reply: "" + reply.seq };

                  msg = Drafty.insertImage(null, 0, {
                    mime: b64.mime,
                    preview: b64.bits,
                    width,
                    height,
                    filename: name,
                    size: blob.size,
                  });

                  msg = Drafty.append(
                    Drafty.sanitizeEntities(reply.content),
                    msg
                  );

                  msg.quote = {
                    name: quote.userName,
                    message: quote.message,
                    seq: quote.seq,
                  };

                  msg = tinode.createMessage(chatTopic, msg, false);
                  msg.head = Object.assign(msg.head || {}, head);

                  // NO QUOTE
                } else {
                  msg = Drafty.insertImage(null, 0, {
                    mime: b64.mime,
                    // _tempPreview: b64.bits, // This preview will not be serialized.
                    preview: b64.bits, // Serializable preview
                    width,
                    height,
                    filename: name,
                    size: blob.size,
                    // urlPromise: uploadCompletionPromise,
                  });
                  msg = tinode.createMessage(chatTopic, msg, false);
                }

                // Pass data and the uploader to the TinodeWeb.
                // const message = tinode.createMessage(chatTopic, msg);
                tinode
                  .publishMessage(msg)
                  .then((data: any) => {
                    console.log("++SEND IMAGE", msg);
                    console.log("++SEND IMAGE ANSWER", data);
                    setQuote(null);
                    setSpiner(false);
                  })
                  .catch((error: any) => {
                    console.log("++SEND IMAGE ERROR", error);
                    setSpiner(false);
                  });
              });
            })
            .catch((err: any) => {
              console.log("+++ IMAGE SCALE ERROR", err);
            });
        }
      }
    }
    return undefined;
  };

  const addSmile = (sm: string) => {
    //
    if (sm) {
      let msg = null;

      // WITH QUOTE
      if (quote) {
        const autorLetter = quote.userName[0].toUpperCase();
        const reply = {
          content: Drafty.quote(
            autorLetter,
            quote.userId,
            Drafty.init(quote.message)
          ),
          seq: quote.seq,
        };
        let head = { reply: "" + reply.seq };

        msg = Drafty.append(Drafty.sanitizeEntities(reply.content), msg);
        msg.quote = {
          name: quote.userName,
          message: quote.message,
          seq: quote.seq,
        };

        msg.fileType = "smile";
        msg.fileUrl = `smile${sm}.png`;

        msg = tinode.createMessage(chatTopic, msg, false);
        msg.head = Object.assign(msg.head || {}, head);

        // NO QUOTE
      } else {
        msg = tinode.createMessage(chatTopic, {
          fileType: "smile",
          fileUrl: `smile${sm}.png`,
        });
      }

      // console.log("++ CREATE MESSAGE", msg);
      tinode.publishMessage(msg).then((data: any) => {
        setQuote(null);
        console.log("++SEND ANSWER", data);
      });
    }
  };

  useEffect(() => {
    //
    // console.log("+++ CHAT TOPIC", chatTopic);
    // console.log("+++ CHAT SECRET", chatSecret);
    //
    if (tinode && !tinode.isAuthenticated() && !tinode.isConnected()) {
      // console.log("++++++++++++++++++++++++++++++++");
      tinode.setHumanLanguage("en");
      tinode.enableLogging(false, true);

      if (!tinode.isConnected() && chatSecret) {
        tinode.connect().then(() => {
          // console.log("CONNECTED");

          tinode
            .login("basic", chatSecret)
            .then((data: any) => {
              // console.log("LOGINED", data);
              setSpiner(false);

              tinode.cacheRemTopic(chatTopic);

              let topic = tinode.getTopic(chatTopic);
              // console.log("++ GET TOPIC", topic);

              if (topic) {
                // console.log("++ TOPIC IS DEFINE", chatTopic);

                const reGetMeta = () => {
                  topic
                    .getMeta(topic.startMetaQuery().withSub().build())
                    .then((meta: any) => {
                      setTimeout(() => reGetMeta(), 10000);
                    })
                    .catch((error: any) => {
                      setTimeout(() => reGetMeta(), 10000);
                    });
                };

                setTimeout(() => reGetMeta(), 1000);

                //
                topic.onData = (data: any) => {
                  console.log("++ ON DATA", data);

                  let message = "";
                  let imageUrl = "";
                  let imgBlob: any = "";
                  let fileUrl = "";
                  let fileName = "";
                  let smileUrl = "";
                  let fileMime = "";
                  let quoteName = "";
                  let quoteMessage = "";
                  let quoteSeq = "";

                  const incoming: any = path(["content"], data);

                  // MESSAGE
                  if (typeof incoming === "string") message = incoming;
                  else if (path(["content", "txt"], data)) {
                    message = path(["content", "txt"], data) || "";
                  }

                  if (path(["content", "singleText"], data)) {
                    message = path(["content", "singleText"], data);
                  }

                  //  BLOB 0
                  if (
                    path(["content", "ent", 0, "data", "mime"], data) &&
                    path(["content", "ent", 0, "data", "val"], data)
                  ) {
                    const val = path(
                      ["content", "ent", 0, "data", "val"],
                      data
                    );
                    const mime = path(
                      ["content", "ent", 0, "data", "mime"],
                      data
                    );
                    imgBlob = `data:${mime};base64,${val}`;
                    message = "";
                  }

                  //  BLOB 1
                  if (
                    path(["content", "ent", 1, "data", "mime"], data) &&
                    path(["content", "ent", 1, "data", "val"], data)
                  ) {
                    const val = path(
                      ["content", "ent", 1, "data", "val"],
                      data
                    );
                    const mime = path(
                      ["content", "ent", 1, "data", "mime"],
                      data
                    );
                    imgBlob = `data:${mime};base64,${val}`;
                    message = "";
                  }

                  // SMILE
                  if (path(["content", "fileType"], data) === "smile") {
                    smileUrl = path(["content", "fileUrl"], data) || "";
                    if (smileUrl) smileUrl = `/assets/images/${smileUrl}`;
                    message = "";
                  }

                  // ANY FILE
                  if (path(["content", "fileType"], data) === "file") {
                    fileUrl = path(["content", "fileUrl"], data) || "";

                    if (fileUrl)
                      // fileUrl =
                      //   "https://" + baseChatUrl + tinode.authorizeURL(fileUrl);
                      fileUrl = tinode.authorizeURL(fileUrl);
                    fileName = path(["content", "fileName"], data) || "";
                    fileMime = path(["content", "fileMime"], data) || "";
                    message = "";
                  }

                  // QUOTE
                  if (path(["content", "quote", "name"], data)) {
                    quoteName = path(["content", "quote", "name"], data);
                    quoteMessage = path(["content", "quote", "message"], data);
                    quoteSeq = path(["content", "quote", "seq"], data);
                  }

                  // DIVIDER
                  let divider = false;
                  const notifyList = store.getState().notifyList || [];
                  if (notifyList && wishId) {
                    // console.log("++ CHAT NOTIFY", notifyList);
                    // console.log("++ ON DATA NOTIFY LIST", notifyList);
                    // console.log("++ ON DATA WISH ID", wishId);

                    const dividerList: any = notifyList.filter(
                      (nItem: any) =>
                        nItem.referenceable.uuid === wishId &&
                        nItem.type === "wish_new_message" &&
                        nItem.divider
                    );

                    if (dividerList.length > 0) {
                      const dividerCreateAt = Math.floor(
                        Date.parse(dividerList[0].created_at) / 1000
                      );
                      const messageCreateAt = Math.floor(
                        Date.parse(data.ts) / 1000
                      );

                      if (
                        dividerCreateAt === messageCreateAt ||
                        dividerCreateAt + 1 === messageCreateAt ||
                        dividerCreateAt - 1 === messageCreateAt
                      ) {
                        divider = true;
                      }

                      console.log(
                        " ====== +++++++ DIVIDER CREATE AT",
                        dividerCreateAt
                      );
                      console.log(
                        " ====== +++++++ MESSAGE CREATE AT",
                        messageCreateAt
                      );
                    }
                  }

                  const hh = new Date(data.ts)
                    .getHours()
                    .toString()
                    .padStart(2, "0");
                  const mm = new Date(data.ts)
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");
                  const ss = new Date(data.ts)
                    .getSeconds()
                    .toString()
                    .padStart(2, "0");

                  if (message || imageUrl || fileUrl || smileUrl || imgBlob) {
                    const msg = {
                      id: data.seq,
                      from: data.from,
                      isMe: tinode.isMe(data.from),
                      message,
                      imageUrl,
                      fileMime,
                      imgBlob,
                      fileUrl,
                      fileName,
                      smileUrl,
                      quoteName,
                      quoteMessage,
                      quoteSeq,
                      ts: `${hh}:${mm}:${ss}`,
                      created_at: data.ts,
                      divider,
                    };

                    setMessages((oldMessages: any[]) =>
                      [...oldMessages, msg]
                        .sort((a: any, b: any) => a.id - b.id)
                        .slice(-100)
                    );
                    const scrollbar: HTMLElement | null =
                      document.querySelector(".chat-extend-scrollbar");
                    if (scrollbar)
                      setTimeout(() => {
                        scrollbar.scrollTop += 20000;
                        // console.log(scrollbar.scrollTop);
                      }, 200);
                  }
                };

                //
                topic.onMeta = (meta: any) => {
                  // console.log("++ ON META", meta);
                  const usersList: any[] = path(["sub"], meta) || [];
                  if (usersList.length > 1) setUsers(usersList);
                };

                topic
                  .subscribe(
                    topic
                      .startMetaQuery()
                      .withSub()
                      .withData(topic.seq - 20, topic.seq + 1, 20)
                      .build()
                  )
                  .then((data: any) => {
                    // console.log("++ SUBSCRIBE SUCCESS", data);
                    // topic.getMessagesPage(20, true).then((msg: any) => {
                    //   console.log("+++++++++ MESSAGE", msg);
                    // });
                  })
                  .catch((error: any) => {
                    // console.log("-- SUBSCRIBE ERROR", error);
                  });
              } else {
                // console.log("++ TOPIC IS UNDEFINED");
              }
            })
            .catch((error: any) => {
              // console.log("-- LOGIN ERROR", error);
            });
        });
      }
    }

    return () => {
      // console.log("UNMOUNT", tinode);
      tinode.disconnect();
    };
  }, [tinode]);

  return (
    <>
      {variant === "extend" && (
        <div className="chat-preview">
          <div className="chat-header__avatars">
            {users
              .filter((usr: any) => usr.online)
              .slice(0, 3)
              .map((usr: any, id: number) => {
                const avatar = user[usr.user]?.image || avatarDefault;
                return (
                  <div className="chat-header__avatar" key={id}>
                    <img src={avatar} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div className="chat">
        <div className="chat-header">
          {variant === "extend" && (
            <>
              <div className="chat-header__title">Кружок коллег</div>
              <div className="chat-header__count">
                Сейчас онлайн:{" "}
                <span>{users.filter((user: any) => user.online).length}</span>
              </div>
            </>
          )}

          {variant === "profile" && (
            <>
              <div className="chat-header__avatars">
                {users
                  .filter((usr: any) => !tinode.isMe(usr.user))
                  .filter((usr: any) => usr.online)
                  .slice(0, 3)
                  .map((usr: any, id: number) => {
                    const avatar = user[usr.user]?.image || avatarDefault;
                    return (
                      <div className="chat-header__avatar" key={id}>
                        <img src={avatar} alt="" />
                      </div>
                    );
                  })}
              </div>
              <div className="chat-header__members">
                <div className="chat-header__members-title">
                  {users
                    .filter((usr: any) => !tinode.isMe(usr.user))
                    .filter((usr: any) => usr.online).length > 0 && (
                    <span>Сейчас онлайн:</span>
                  )}
                  {users
                    .filter((usr: any) => !tinode.isMe(usr.user))
                    .filter((usr: any) => usr.online).length === 0 && (
                    <span>Нет исполнителей онлайн</span>
                  )}
                </div>
                <div className="chat-header__members-names">
                  {users
                    .filter((usr: any) => !tinode.isMe(usr.user))
                    .filter((usr: any) => usr.online)
                    .slice(0, 3)
                    .map((usr: any, i: number) => {
                      const name = user[usr.user]?.name || "Noname";
                      return (
                        <span key={i}>
                          {i > 0 ? "," : ""} {name}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="chat-body">
          <Scrollbar className="chat-extend-scrollbar">
            {messages.map((message: any, id: number) => {
              //
              if (message.isMe === false) {
                const avatar = user[message.from]?.image || avatarDefault;
                const name = user[message.from]?.name || "Loading...";
                const uuid = user[message.from]?.uuid || null;
                const from = message.from || null;
                const seq = message.id || null;
                const hasDivider = message.divider;
                // console.log(":::: MESSAGE", message);
                return (
                  <div key={id}>
                    {hasDivider && (
                      <div className="chat-body__divider">
                        Непрочитанные сообщения
                      </div>
                    )}
                    <div
                      className="chat-body__message chat-body__message--user"
                      id={"seq" + message.id}
                    >
                      {uuid && (
                        <Link to={`/user/${uuid}`}>
                          <img
                            className="chat-body__avatar"
                            src={avatar}
                            alt=""
                          />
                        </Link>
                      )}
                      {!uuid && (
                        <img
                          className="chat-body__avatar"
                          src={avatar}
                          alt=""
                        />
                      )}
                      <div className="chat-body__message-data chat-body__message-data--user">
                        <div className="chat-body__message-inner">
                          {/* QUOTE IN MESSAGE */}
                          {message.quoteName && message.quoteSeq && (
                            <button
                              type="button"
                              className={`btn-reset chat-body__quote parent-seq-${message.quoteSeq}`}
                              onClick={() => {
                                const origin = document.getElementById(
                                  `seq${message.quoteSeq}`
                                );
                                const scrollbar: HTMLElement | null =
                                  document.querySelector(
                                    ".chat-extend-scrollbar"
                                  );
                                if (origin && scrollbar) {
                                  scrollbar.scrollTop = origin.offsetTop;
                                  origin.classList.add(
                                    "chat-body__message--show-origin"
                                  );
                                  setTimeout(() => {
                                    origin.classList.remove(
                                      "chat-body__message--show-origin"
                                    );
                                  }, 2000);
                                }
                              }}
                            >
                              <span className="chat-body__quote-name">
                                {message.quoteName}
                              </span>
                              <span className="chat-body__quote-message">
                                {message.quoteMessage}
                              </span>
                            </button>
                          )}

                          <div className="chat-body__user-name">
                            {name}

                            {/* ADD QUOTE BUTTON */}
                            <button
                              type="button"
                              className="btn-reset chat-quote__add-button"
                              onClick={() => {
                                setQuote({
                                  userName: name,
                                  userId: from,
                                  message: message.message || "",
                                  seq: seq,
                                });
                              }}
                            >
                              <img
                                className="file-icon"
                                src={iconQuote}
                                alt=""
                              />
                            </button>
                          </div>
                          <div className="chat-body__user-text">
                            {/*  */}
                            {message.message && <span>{message.message}</span>}
                            {/*  */}
                            {message.imgBlob && (
                              <img
                                className="wide-image"
                                src={message.imgBlob}
                                alt=""
                              />
                            )}
                            {/*  */}
                            {message.smileUrl && (
                              <img
                                className="smile-image"
                                src={message.smileUrl}
                                alt=""
                              />
                            )}
                            {/*  */}
                            {message.fileUrl && (
                              <>
                                <img
                                  className="file-icon"
                                  src={iconFile}
                                  alt=""
                                />
                                <span className="file-name">
                                  {message.fileName}
                                </span>

                                <button
                                  type="button"
                                  className="file-link"
                                  // href={message.fileUrl}
                                  onClick={() => {
                                    console.log(
                                      "FILE DOWNLOAD CLICK",
                                      message.fileUrl
                                    );

                                    const url = message.fileUrl;
                                    const filename = message.fileName;
                                    const mimetype = message.fileMime;

                                    const downloader =
                                      tinode.getLargeFileHelper();
                                    downloader.download(
                                      url,
                                      filename,
                                      mimetype,
                                      (loaded: any) => {
                                        console.log(loaded);
                                      },
                                      (err: any) => {
                                        console.log(err);
                                      }
                                    );
                                  }}
                                >
                                  Скачать
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="chat-body__message-meta">
                          <div className="chat-body__message-date">
                            {message.ts}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (message.isMe === true) {
                // console.log(":::: MESSAGE", message);
                return (
                  <div
                    className="chat-body__message chat-body__message--my"
                    id={"seq" + message.id}
                    key={id}
                  >
                    <div className="chat-body__message-data chat-body__message-data--my">
                      <div className="chat-body__message-inner">
                        {/* QUOTE IN MESSAGE */}
                        {message.quoteName && message.quoteSeq && (
                          <button
                            type="button"
                            className={`btn-reset chat-body__quote parent-seq-${message.quoteSeq}`}
                            onClick={() => {
                              const origin = document.getElementById(
                                `seq${message.quoteSeq}`
                              );
                              const scrollbar: HTMLElement | null =
                                document.querySelector(
                                  ".chat-extend-scrollbar"
                                );
                              if (origin && scrollbar) {
                                scrollbar.scrollTop = origin.offsetTop;
                                origin.classList.add(
                                  "chat-body__message--show-origin"
                                );
                                setTimeout(() => {
                                  origin.classList.remove(
                                    "chat-body__message--show-origin"
                                  );
                                }, 2000);
                              }
                            }}
                          >
                            <span className="chat-body__quote-name">
                              {message.quoteName}
                            </span>
                            <span className="chat-body__quote-message">
                              {message.quoteMessage}
                            </span>
                          </button>
                        )}

                        <div className="chat-body__user-text">
                          {/* */}
                          {message.message && <span>{message.message}</span>}
                          {/* */}
                          {message.imgBlob && (
                            <img
                              className="wide-image"
                              src={message.imgBlob}
                              alt=""
                            />
                          )}
                          {/*  */}
                          {message.smileUrl && (
                            <img
                              className="smile-image"
                              src={message.smileUrl}
                              alt=""
                            />
                          )}
                          {/*  */}
                          {message.fileUrl && (
                            <>
                              <img
                                className="file-icon"
                                src={iconFile}
                                alt=""
                              />
                              <span className="file-name">
                                {message.fileName}
                              </span>
                              {/* <a href={message.fileUrl}>11111111111111</a> */}

                              <button
                                type="button"
                                className="file-link"
                                // href={message.fileUrl}
                                onClick={() => {
                                  console.log(
                                    "FILE DOWNLOAD CLICK",
                                    message.fileUrl
                                  );

                                  const url = message.fileUrl;
                                  const filename = message.fileName;
                                  const mimetype = message.fileMime;

                                  const downloader =
                                    tinode.getLargeFileHelper();
                                  downloader.download(
                                    url,
                                    filename,
                                    mimetype,
                                    (loaded: any) => {
                                      console.log(loaded);
                                    },
                                    (err: any) => {
                                      console.log(err);
                                    }
                                  );
                                }}
                              >
                                Скачать
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="chat-body__message-meta">
                        <div className="chat-body__message-date">
                          {message.ts}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return <></>;
            })}
          </Scrollbar>

          {/* Quote body */}
          {quote && (
            <div className="chat-quote__body">
              <img
                src={iconQuoteIndicator}
                className="chat-quote__indicator"
                alt=""
              />
              <span className="chat-quote__user-name">{quote.userName}</span>
              <span className="chat-quote__user-message">{quote.message}</span>

              <button
                type="button"
                className="btn-reset chat-quote__rem-button"
                onClick={() => {
                  setQuote(null);
                }}
              >
                <img src={iconClose} alt="" />
              </button>
            </div>
          )}

          {spiner && (
            <div className="chat-body__spiner">
              <span />
              <span />
              <span />
            </div>
          )}
        </div>

        <div className="chat-footer">
          <textarea
            name="message"
            id=""
            className="chat-footer__textarea"
            value={type}
            disabled={!willConnect}
            maxLength={500}
            onInput={(e: any) => {
              const msg = e.target.value;
              setType(msg);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
          />

          <button
            type="button"
            className="btn-reset chat-footer__send-button"
            onClick={() => sendMessage()}
          >
            Отправить
          </button>
          <AddMedia addFile={addFile} addImage={addImage} />
          <AddSmile addSmile={addSmile} />
        </div>
      </div>
    </>
  );
};
