import React from "react";
import { Form } from "components/form";
import {
  InputText,
  InputPass,
  InputDate,
  SelectCity,
  Checkbox,
  Recaptcha,
} from "components/input";
import { Button } from "components/button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export const FormReg = () => (
  <Form className="form mt-3">
    <div className="row">
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Укажи настоящее имя, чтобы коллеги смогли найти тебя и исполнить
              твой виш
            </Popover.Body>
          </Popover>
        }
      >
        <div className="col-12 col-sm-12 mb-2 mb-sm-3">
          <InputText
            label="Имя*"
            name="name"
            placeholder="Введи имя"
            role="formRegName"
            autocomplete="username"
          />
        </div>
      </OverlayTrigger>

      {/* <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Укажи настоящее имя, чтобы коллеги смогли найти тебя и
              исполнить твой виш
            </Popover.Body>
          </Popover>
        }
      >
        <div className="col-12 col-sm-6 mb-2 mb-sm-3">
          <InputText
            label="Фамилия*"
            name="surname"
            placeholder="Введи фамилию"
            role="formRegSurname"
          />
        </div>
      </OverlayTrigger> */}
    </div>

    <div className="row">
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Необходимо указать личный, а не рабочий e-mail
            </Popover.Body>
          </Popover>
        }
      >
        <div className="col-12 col-sm-6 mb-2 mb-sm-3">
          <InputText
            label="Личный e-mail*"
            name="email"
            placeholder="Введи личный e-mail"
            role="formRegEmail"
          />
        </div>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Найти табельный номер можно в расширенном поиске портала
              "МегаПолис". МегаПолис — Расширенный поиск сотрудника — Ф.И.О — в
              открывшемся окне ты найдешь табельный номер.
            </Popover.Body>
          </Popover>
        }
      >
        <div className="col-12 col-sm-6 mb-2 mb-sm-3">
          <InputText
            label="Табельный номер*"
            name="prNumber"
            placeholder="Введи номер"
            role="formRegNumber"
          />
        </div>
      </OverlayTrigger>
    </div>

    <div className="row">
      <div className="col-12 col-sm-6 mb-2 mb-sm-3">
        <SelectCity
          label="Город*"
          name="city"
          id="formRegCity"
          placeholder="Выбери город"
        />
      </div>

      <div className="col-12 col-sm-6 mb-2 mb-sm-3 form-control-smaller-text">
        <InputDate
          label="День и месяц рождения*"
          name="date"
          placeholder="Введи день и месяц рождения"
          role="formRegBirthday"
        />
      </div>
    </div>

    <div className="row">
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Пароль должен состоять минимум из 8 символов и содержать буквы
              латиницы верхнего и нижнего регистра и цифры. Пароль не должен
              совпадать с доменным.
            </Popover.Body>
          </Popover>
        }
      >
        <div className="col-12 col-sm-6 mb-2 mb-sm-4">
          <InputPass
            label="Пароль*"
            name="password"
            placeholder="Введи пароль"
            role="formRegPass"
            autocomplete="new-password"
          />
        </div>
      </OverlayTrigger>

      <div className="col-12 col-sm-6 mb-4">
        <InputPass
          label="Повтор пароля*"
          name="passConfirm"
          placeholder="Повтори пароль"
          role="formRegPassConfirm"
          autocomplete="new-password"
        />
      </div>

      <div className="col-12 mb-3 mb-sm-4">
        <Checkbox
          name="personal"
          id="formRegAgreement"
          value="1"
          role="formRegAgreement"
          text={
            <>
              Даю{" "}
              <a
                href={`${process.env.PUBLIC_URL}/assets/documents/personal_data.pdf`}
                target="_blank"
                rel="noreferrer"
                // download
              >
                согласие
              </a>{" "}
              на обработку моих персональных данных*
            </>
          }
        />
      </div>

      <div className="col-12 mb-4">
        <Checkbox
          name="agreement"
          id="formRegRules"
          value="1"
          role="formRegRules"
          text={
            <>
              С{" "}
              <a
                href={`${process.env.PUBLIC_URL}/assets/documents/agreement.pdf`}
                target="_blank"
                rel="noreferrer"
                // download
              >
                Пользовательским соглашением
              </a>{" "}
              ознакомлен и согласен*
            </>
          }
        />
      </div>

      <div className="col-12 mb-4">
        <Checkbox
          name="rules"
          id="formRegPolitic"
          value="1"
          role="formRegPolitic"
          text={
            <>
              С{" "}
              <a
                href={`${process.env.PUBLIC_URL}/assets/documents/politika_konfiden.pdf`}
                target="_blank"
                rel="noreferrer"
                // download
              >
                Политиками
              </a>{" "}
              о запрете использования на Сайте информации ограниченного доступа
              ознакомлен и согласен*
            </>
          }
        />
      </div>
    </div>

    <div className="form-reg__footer">
      <Recaptcha name="recaptcha" />
      <Button type="submit" role="formRegSubmitButton">
        Зарегистрироваться
      </Button>
    </div>
  </Form>
);
