import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import type { RootState } from "app/store";
import { useSlc, useDsp, action } from "app/store";
import { HeaderNotify } from "components/headerNotify";
import logoBrand from "assets/images/logo-brand.png";
import avatarDefault from "assets/images/avatar-default.svg";

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const HeaderTop = () => {
  const dispatch = useDsp();
  const user = useSlc((state: RootState) => state.user);
  const count = useSlc((state: RootState) => state.dataProfileWishes);
  const avatar = user.image;

  // if (user.isAuth)
  return (
    <header className="header--top" role="headerTop">
      <div className="header--top__container container">
        <div className="header--top__logo">
          <NavLink
            className="header--top__logo--link"
            role="headerTopLogo"
            to="/"
          >
            <img src={logoBrand} alt="" />
          </NavLink>
        </div>
        <nav className="header--top__nav">
          <div className="header--top__nav-wrapper">
            <NavLink
              className="header--top__nav--link header--top__nav--link-purple"
              to="/"
            >
              Главная
            </NavLink>
            <button
              className="btn-reset header--top__nav--link  header--top__nav--link-purple"
              type="button"
              onClick={() => dispatch(action.mKruzokShow())}
            >
              Кружок это
            </button>
            <NavLink className="header--top__nav--link" to="/wishlists">
              Вишлисты
            </NavLink>
            <NavLink className="header--top__nav--link" to="/rating">
              Рейтинг
            </NavLink>
            <button
              className="btn-reset header--top__nav--link "
              type="button"
              onClick={() => dispatch(action.mIntroShow())}
            >
              Подсказки
            </button>
            <button
              className="btn-reset header--top__nav--link active hovered"
              type="button"
              onClick={() => dispatch(action.mFeedbackShow())}
            >
              Обратная связь
            </button>
          </div>
        </nav>
        <div className="header--top__personal-data">
          <div className="header--top__personal-score-container">
            <div className="header--top__personal-score">
              Исполнено мной:
              <span className="header--top__personal-points">{user.balls}</span>
            </div>
            <div className="header--top__personal-score">
              Опубликовано мной:
              <span className="header--top__personal-points">
                {count.length}
              </span>
            </div>
          </div>

          <NavLink
            className="header--top__personal-avatar"
            to="/profile"
            role="headerTopAvatar"
          >
            <img src={avatar ? baseurl + avatar : avatarDefault} alt="" />
          </NavLink>
          <HeaderNotify />
        </div>
      </div>
    </header>
  );
  // return <></>;
};
