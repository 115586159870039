import React, { useState } from "react";
import { Form, useFormikContext } from "components/form";
import { InputText, Select, SelectCity, SelectHash } from "components/input";
import { Button } from "components/button";

type TCatTag = {
  value: string;
  label: string;
};

export const FormWishCreate = ({ categoryList }: any) => {
  const [tags, newTags] = useState([]);
  const { values }: any = useFormikContext();

  if (values && values.wishName && values.wishCategory && tags.length === 0) {
    const createdTags = values.wishName
      .split(" ")
      .filter((w: string) => w.length > 2)
      .map((tag: string) => tag.slice(0, 15))
      .map((tag: string) => ({
        value: tag,
        label: tag,
      }));
    const categoryTag: TCatTag = {
      value: values.wishCategory,
      label: values.wishCategory,
    };
    createdTags.push(categoryTag);

    newTags(createdTags);
  }

  return (
    <Form className="form">
      <div className="row">
        <div className="col-12 mb-3">
          <InputText
            label="Название"
            name="wishName"
            placeholder="Введи название"
            role="formWishCreateName"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <InputText
            // as="textarea"
            // className="form-wish-create__textarea"
            label="Описание"
            name="wishDesc"
            placeholder="Введи описание"
            role="formWishCreateDescr"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <Select
            name="wishCategory"
            label="Категория"
            placeholder="Выбери вишлист"
            id="formWishCreateCategory"
            options={categoryList}
          />
        </div>
      </div>

      {values && values.wishCategory && (
        <div className="row">
          <div className="col-12 mb-3">
            <SelectHash
              name="tags"
              label="Хэштеги"
              placeholder="Например, “рыбалка”, “поход”"
              id="formWishCreateTags"
              customTags={tags}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 mb-3">
          <SelectCity
            name="city"
            label="Место"
            placeholder="Любой город"
            id="formWishCreateCity"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <Select
            name="memberAmount"
            label="Количество участников"
            placeholder="Кол-во"
            id="formWishCreateMembers"
            options={[
              {
                value: "1",
                label: "1",
              },
              {
                value: "2",
                label: "2",
              },
              {
                value: "3",
                label: "3",
              },
              {
                value: "4",
                label: "4",
              },
              {
                value: "5",
                label: "5",
              },
              {
                value: "6",
                label: "6",
              },
              {
                value: "7",
                label: "7",
              },
              {
                value: "8",
                label: "8",
              },
              {
                value: "9",
                label: "9",
              },
              {
                value: "10",
                label: "10",
              },
            ]}
          />
        </div>
      </div>

      <Button
        type="submit"
        className="mx-auto mt-3"
        role="formWishCreateSubmitButton"
      >
        Опубликовать
      </Button>
    </Form>
  );
};
