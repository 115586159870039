import { createAction, createReducer } from "@reduxjs/toolkit";

const fWishListShow = createAction("fWishListShow");
const fWishListHide = createAction("fWishListHide");

const fWishListIsShow = createReducer(false, (builder) => {
  builder.addCase(fWishListShow, (state, action) => true);
  builder.addCase(fWishListHide, (state, action) => false);
});

// WISHLISTS FILTER
const fWishListsCitySet = createAction<any>("fWishListsCitySet");
const fWishListsCity = createReducer(
  {
    value: "",
    label: "Все города",
  },
  (builder) => {
    builder.addCase(fWishListsCitySet, (state, action) => action.payload);
  }
);

const fWishListsNameSet = createAction<any>("fWishListsNameSet");
const fWishListsName = createReducer(
  {
    value: "",
  },
  (builder) => {
    builder.addCase(fWishListsNameSet, (state, action) => action.payload);
  }
);

const fWishListsCountSet = createAction<any>("fWishListsCountSet");
const fWishListsCount = createReducer(
  {
    value: "12",
    label: "Вид: 12",
  },
  (builder) => {
    builder.addCase(fWishListsCountSet, (state, action) => action.payload);
  }
);

const fWishPaginationSet = createAction<any>("fWishPaginationSet");
const fWishPagination = createReducer(
  {
    page: 1,
    count: 1,
  },
  (builder) => {
    builder.addCase(fWishPaginationSet, (state, action) => action.payload);
  }
);

// PROFILE WISHES
const filterProfileWishesSet = createAction<any>("filterProfileWishesSet");
const filterProfileWishes = createReducer(
  {
    value: "all",
    label: "Все статусы виша",
  },
  (builder) => {
    builder.addCase(filterProfileWishesSet, (state, action) => action.payload);
  }
);

// PROFILE PERFORMERS
const filterProfilePrfsSet = createAction<any>("filterProfilePrfsSet");
const filterProfilePrfs = createReducer(
  {
    value: "all",
    label: "Все статусы виша",
  },
  (builder) => {
    builder.addCase(filterProfilePrfsSet, (state, action) => action.payload);
  }
);

// RATING FILTER
const fRatingCitySet = createAction<any>("fRatingCitySet");
const fRatingCity = createReducer(
  {
    value: "",
    label: "Все города",
  },
  (builder) => {
    builder.addCase(fRatingCitySet, (state, action) => action.payload);
  }
);

const fRatingPeriodSet = createAction<any>("fRatingPeriodSet");
const fRatingPeriod = createReducer(
  {
    value: "all",
    label: "За все время",
  },
  (builder) => {
    builder.addCase(fRatingPeriodSet, (state, action) => action.payload);
  }
);

const fRatingPaginationSet = createAction<any>("fRatingPaginationSet");
const fRatingPagination = createReducer(
  {
    page: 1,
    count: 1,
  },
  (builder) => {
    builder.addCase(fRatingPaginationSet, (state, action) => action.payload);
  }
);

// HOOK RELOAD
const hookReloadSet = createAction<number>("hookReloadSet");
const hookReload = createReducer(0, (builder) => {
  builder.addCase(hookReloadSet, (state, action) => action.payload);
});

//
export const actionFilter = {
  fWishListShow,
  fWishListHide,
  fWishListsCitySet,
  fWishListsNameSet,
  fWishListsCountSet,
  fWishPaginationSet,
  filterProfileWishesSet,
  filterProfilePrfsSet,
  //
  fRatingCitySet,
  fRatingPeriodSet,
  fRatingPaginationSet,
  //
  hookReloadSet,
};

export const reducerFilter = {
  fWishListIsShow,
  fWishListsCity,
  fWishListsName,
  fWishListsCount,
  fWishPagination,
  filterProfileWishes,
  filterProfilePrfs,
  //
  fRatingCity,
  fRatingPeriod,
  fRatingPagination,
  //
  hookReload,
};
