import { urls } from "app/constants";
import { axiosSecure } from "./_api";

function addDays(dateObj: Date, numDays: number) {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
}

export const dtoUserRating = (param: any) => {
  const obj: any = {};
  if (param.city) obj.city = param.city;
  if (param.period === "week") {
    const today = new Date();
    obj.date_from = addDays(new Date(), -7);
    obj.date_to = today.toISOString();
  }
  if (param.period === "month") {
    const today = new Date();
    obj.date_from = addDays(new Date(), -30);
    obj.date_to = today.toISOString();
  }
  if (param.perPage) obj.per_page = param.perPage;
  if (param.page) obj.page = param.page || 1;
  return obj;
};

export const apiUserRating = (params: any, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.userRating,
    method: "get",
    params: dtoUserRating(params),
  })
    .then(success)
    .catch(unsuccess);
};
