import { urls } from "app/constants";
import { dtoFormikServerErr, dtoProfile } from "app/utils/dto";
import { axiosSecure } from "./_api";

export const apiProfile = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.profile,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiSetProfile = (
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosSecure({
    url: urls.profile,
    method: "post",
    data: dtoProfile(params),
  })
    .then(success)
    .catch((error) => {
      // console.log("API SET PROFILE ERROR 400", error);
      const errorObject = dtoFormikServerErr(error.response.data);
      setErrors(errorObject);
      return unsuccess(error);
    });
};

export const apiProfileWishes = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.profileWishes,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiProfilePerformers = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.profilePerformers,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiAvatar = (data: any, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.profileAvatar,
    method: "post",
    data: data,
  })
    .then(success)
    .catch(unsuccess);
};
