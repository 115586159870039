import * as Yup from "yup";
import { messages } from "app/constants";

const regx = {
  // name: /^[а-яА-ЯёЁ]+$/,
  name: /^([-\s]{0,1})([а-яА-ЯёЁ'-]+(?:\s[а-яА-ЯёЁ'-]+){0,2})([-\s]{0,1})$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$/,
  // date: /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19[3456789][0-9]|20[012][0-9])$/,
  date: /^(19[3456789][0-9]|20[012][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
  // password:
  //   /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9@&#$%_\~\-\.\,\!\"\'\`\(\)\*\+\/\:\;\<\=\>\?\[\\\]\^\{\}\|]{5,}$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@&#$%_\~\-\.\,\!\"\'\`\(\)\*\+\/\:\;\<\=\>\?\[\\\]\^\{\}\|]{8,}$/,
  prNumber: /^[0-9]{1,8}$/,
  tag: /^[а-яА-ЯёЁ]+$/,
  // phone: /^[+]{1}[7\(]{1}[0-9]{3}[\)]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}$/,
  phone:
    /^[\+]{1}[7]{1}[\(){1}][0-9]{3}[\)]{1}[0-9]{3}[\-]{1}[0-9]{2}[\-]{1}[0-9]{2}$/,
  telega: /^[@]{1}[a-zA-Z0-9-_]{3,}$/,
};

const formError = Yup.string();

const name = Yup.string()
  .matches(regx.name, messages.name.format)
  .min(2, messages.name.toShort)
  .max(20, messages.name.toLong)
  .required(messages.name.required);

const surname = Yup.string()
  .matches(regx.name, messages.name.format)
  .min(2, messages.name.toShort)
  .max(20, messages.name.toLong)
  .required(messages.surname.required);

const email = Yup.string()
  .matches(regx.email, messages.email.format)
  .email(messages.email.format)
  .test("test2", messages.email.forbidden, (value: any) => {
    const list = [
      "megafon.ru",
      "onefactor.com",
      "megalabs.ru",
      "talmer.ru",
      "nexign.com",
      "megafon-retail.ru",
      "megatech.ru",
      "@yotateam.com",
      "pikta.ru",
    ];
    let result = true;
    list.map((item) => {
      if (value && value.toLowerCase().indexOf(item) > -1) {
        result = false;
      }
    });
    return result;
  })

  .max(30, messages.email.toLong)
  .required(messages.email.required);

const prNumber = Yup.string()
  .matches(regx.prNumber, messages.prNumber.format)
  .required(messages.prNumber.required);

const citySelect = Yup.string().required(messages.citySelect.required);
const cityOption = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});
const select = Yup.string().required(messages.select.required);
// const selectMultiple = Yup.array().min(1, messages.select.minOne);
const selectDefault = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});

const message = Yup.string()
  .max(1000, messages.message.toBig)
  .required(messages.message.required);

const tags = Yup.array()
  .test("test1", messages.wishTag.count, (value) => {
    if (value?.every((el) => el.length > 2 && el.length < 16)) return true;
    return false;
  })
  // .test("test2", messages.wishTag.format, (value) => {
  //   if (value?.every((el) => regx.tag.test(el))) return true;
  //   return false;
  // })
  .min(1, messages.wishTag.minOne)
  .max(10, messages.wishTag.maxLimit);

const phone = Yup.string().matches(regx.phone, messages.phone.format);

const telega = Yup.string().matches(regx.telega, messages.telega.format);

const files = Yup.array()
  .test("test1", messages.gallery.toBig, (value: any) => {
    if (value.every((el: any) => el.size < 5200000)) return true;
    return false;
  })
  .min(1, "мин 1")
  .max(3, "макс 3");

const date = Yup.string()
  .nullable(true)
  .matches(regx.date, messages.date.format)
  .required(messages.date.required);

const password = Yup.string()
  .min(8, messages.password.toShort)
  .max(40, messages.password.toLong)
  .matches(regx.password, messages.password.format)
  .required(messages.password.required);

const passConfirm = Yup.string()
  .min(5, messages.password.toShort)
  .max(40, messages.password.toLong)
  .matches(regx.password, messages.password.format)
  .required(messages.passConfirm.required)
  .oneOf([Yup.ref("password"), null], messages.password.notMatch);

const description = Yup.string().max(500, messages.description.toLong);
// .required(messages.description.required);
// .min(10, messages.description.toShort)

const wishName = Yup.string()
  .min(5, messages.wishName.toShort)
  .max(50, messages.wishName.toLong)
  .required(messages.wishName.required);

const wishDesc = Yup.string()
  .min(5, messages.wishDesc.toShort)
  .max(600, messages.wishDesc.toLong)
  .required(messages.wishDesc.required);

const personal = Yup.bool().oneOf([true], messages.personal.required);

const agreement = Yup.bool().oneOf([true], messages.agreement.required);

const rules = Yup.bool().oneOf([true], messages.rules.required);

const shape = (obj: any) => Yup.object().shape({ ...obj });

const recaptcha = Yup.string().required(messages.recaptcha.required);

// SCHEMAS
export const schemas = {
  regis: shape({
    name,
    email,
    prNumber,
    city: citySelect,
    date,
    password,
    passConfirm,
    personal,
    agreement,
    rules,
    recaptcha,
  }),
  login: shape({ email, password, formError }),
  profile: shape({
    city: cityOption,
    // date,
    phone,
    telega,
    description,
  }),
  wishCreate: shape({
    wishName,
    wishDesc,
    wishCategory: select,
    tags,
    city: citySelect,
    memberAmount: select,
  }),
  galleryUpload: shape({
    files,
  }),
  feedback: shape({
    name,
    email,
    theme: select,
    message,
  }),
  myWish: shape({
    wishName,
    wishDesc,
    wishCategory: selectDefault,
    tags,
    city: selectDefault,
    memberAmount: selectDefault,
  }),
  rec: shape({ email }),
};

// VALUES
export const values = {
  regis: {
    name: "",
    email: "",
    prNumber: "",
    city: "",
    date: null,
    password: "",
    passConfirm: "",
    personal: false,
    agreement: false,
    rules: false,
    recaptcha: "",
  },
  login: {
    email: "",
    password: "",
    formError: "",
  },
  rec: {
    email: "",
  },
  profile: {
    city: "",
    date: null,
    description: "",
  },
  wishCreate: {
    wishName: "",
    wishDesc: "",
    wishCategory: "",
    tags: [],
    city: "",
    memberAmount: "",
  },
  galleryUpload: {
    files: [],
  },
  feedback: {
    name: "",
    email: "",
    theme: "",
    message: "",
  },
};
