import React, { useEffect } from "react";
import { action, useDsp } from "app/store";
import axios from "axios";
import { useParams } from "react-router-dom";
import RedirectTo from "components/RedirectTo/RedirectTo";

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const EmailConfirm = () => {
  const { id }: any = useParams() || "";

  console.log(id);
  const dispatch = useDsp();

  const success = ({ status }: any) => {
    if (status === 201 && id) {
      console.log("API EMAIL VERIFY SUCCESS", error);
      dispatch(
        action.mInfoSetData({
          title: "Верификация",
          message: "Твоя почта активирована",
        })
      );
      dispatch(action.mInfoShow());
    }
  };

  const error = (error: any) => {
    if (error.response && error.response.status >= 400 && id) {
      console.log("API EMAIL VERIFY ERROR", error);
      dispatch(
        action.mInfoSetData({
          title: "Ошибка",
          message: "Ваша почта не активирована. Обратитесь в поддержку",
        })
      );
      dispatch(action.mInfoShow());
    }
  };

  useEffect(() => {
    axios({ method: "get", url: baseurl + "/api/auth/verify/email/" + id })
      .then(success)
      .catch(error);
  }, []);

  return (
    <>
      <section className="empty-notice bg-decoration">
        <div className="bg-decoration__wrapper" />
      </section>
      <RedirectTo path="/sign-in" />
    </>
  );
};
