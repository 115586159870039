import { createAction, createReducer } from "@reduxjs/toolkit";

const chatExstendShow = createAction("chatExstendShow");
const chatExstendHide = createAction("chatExstendHide");

const chatExstend = createReducer(false, (builder) => {
  builder.addCase(chatExstendShow, (state, action) => true);
  builder.addCase(chatExstendHide, (state, action) => false);
});

export const actionChat = {
  chatExstendShow,
  chatExstendHide,
};

export const reducerChat = {
  chatExstend,
};
