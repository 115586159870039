import { urls } from "app/constants";
import { dtoFormikServerErr, dtoProfile } from "app/utils/dto";
import { axiosSecure } from "./_api";

export const apiGallery = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.myGallery,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiGalleryUpload = (data: any, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.myGallery,
    method: "post",
    data: data,
  })
    .then(success)
    .catch(unsuccess);
};

export const apiGalleryDelete = (
  uuid: string,
  success: any,
  unsuccess: any
) => {
  axiosSecure({
    url: urls.deleteGallery(uuid),
    method: "delete",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiFriendGallery = (
  uuid: string,
  success: any,
  unsuccess: any
) => {
  axiosSecure({
    url: urls.userGallery(uuid),
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};
