import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperGalleryUpload } from "app/wrappers";

export const ModalGallery = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mGallery)}
      onHide={() => dispatch(action.mGalleryHide())}
      title="Добавление фото"
      // dialogClassName="modal-dialog--gallery"
      bodyClassName="modal-body--gallery"
    >
      <WrapperGalleryUpload />
    </ModalBasic>
  );
};
