import React from "react";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { useSlc, RootState } from "app/store";
// import { useParams } from "react-router-dom";
// import { WrapperWishProfile } from "app/wrappers";
// import { SubNav } from "components/nav";
import { WishMakers } from "components/wishMakers/wishMakers";
import { ChatProfile } from "components/chat";
// import { profileLinks } from "app/constants";
import { LinkActionBack } from "components/link";

export const SectionProfilePerformer = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  // const params = useParams();
  if (isAuth)
    return (
      <section
        className="profile-wish bg-decoration"
        role="sectionProfilePerformer"
      >
        <div className="profile-wish__wrapper">
          <div className="profile-wish__form">
            <LinkActionBack to="/profile/wishes">Исполняю виши</LinkActionBack>
            <div className="profile-wish__form-wrapper">
              <h2 className="profile-wish__title">Виш коллеги</h2>
              {/* <WrapperWishProfile /> */}
            </div>
          </div>

          <div className="profile-wish__history">
            <WishMakers />
            <ChatProfile />
          </div>
        </div>
      </section>
    );
  return <RedirectTo path="/sign-in" />;
};
