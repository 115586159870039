import { createAction, createReducer } from "@reduxjs/toolkit";

const preloaderShow = createAction("preloaderShow");
const preloaderHide = createAction("preloaderHide");

const loaderProfileWishesSet = createAction<boolean>("loaderProfileWishesSet");
const loaderProfileWishSet = createAction<boolean>("loaderProfileWishSet");

const preloader = createReducer(true, (builder) => {
  builder.addCase(preloaderShow, (state, action) => true);
  builder.addCase(preloaderHide, (state, action) => false);
});

const loaderProfileWishes = createReducer(true, (builder) => {
  builder.addCase(loaderProfileWishesSet, (state, action) => action.payload);
});

const loaderProfileWish = createReducer(true, (builder) => {
  builder.addCase(loaderProfileWishSet, (state, action) => action.payload);
});

// PRELOADER PERFORMERS
const loaderProfilePrfsSet = createAction<boolean>("loaderProfilePrfsSet");
const loaderProfilePrfSet = createAction<boolean>("loaderProfilePrfSet");
const loaderProfilePrfs = createReducer(false, (builder) => {
  builder.addCase(loaderProfilePrfsSet, (state, action) => action.payload);
});
const loaderProfilePrf = createReducer(true, (builder) => {
  builder.addCase(loaderProfilePrfSet, (state, action) => action.payload);
});

// LOADER WISHLISTS
const loaderWishListsShow = createAction("loaderWishListsShow");
const loaderWishListsHide = createAction("loaderWishListsHide");
const loaderWishLists = createReducer(true, (builder) => {
  builder.addCase(loaderWishListsShow, (state, action) => true);
  builder.addCase(loaderWishListsHide, (state, action) => false);
});

export const actionPreloader = {
  preloaderShow,
  preloaderHide,
  loaderProfileWishesSet,
  loaderProfileWishSet,
  loaderProfilePrfsSet,
  loaderProfilePrfSet,
  loaderWishListsShow,
  loaderWishListsHide,
};

export const reducerPreloader = {
  preloader,
  loaderProfileWishes,
  loaderProfileWish,
  loaderProfilePrfs,
  loaderProfilePrf,
  loaderWishLists,
};
