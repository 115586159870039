import React from "react";
import { ModalBasic } from "components/modal/";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperAvatarCrop } from "app/wrappers";

export const ModalAvatarCrop = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mAvaCrop)}
      onHide={() => dispatch(action.mAvaCropHide())}
      className="modal--crop"
      bodyClassName="modal-body--crop"
      backdrop="static"
    >
      <WrapperAvatarCrop />
    </ModalBasic>
  );
};
