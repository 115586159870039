import React from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { apiWishDone } from "app/api";
import { Button } from "components/button";
import { useNavigate } from "react-router-dom";

export const WrapperWishDone = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();

  const uuid = useSlc((state: RootState) => state.mWishDone);

  const apiSuccsess = ({ status, data }: any, uuid: string) => {
    // console.log("API REFUSE WISH SUCCESS");
    if (status === 200 && data) {
      const currentPerformList = store.getState().dataProfilePrfs;
      const newList = currentPerformList.map((wish: any) => {
        if (wish.uuid === data.uuid) return data;
        return wish;
      });
      dispatch(action.dataProfilePrfsSet(newList));
      // console.log("CURRENT PERFORM WISHES", currentPerformList);

      dispatch(action.mWishDoneSet(""));
      navigate("/profile/performers");
    }
  };

  const apiError = (err: any) => {
    // console.log("API REFUSE WISH SUCCESS", err);
  };

  return (
    <div className="row mt-2">
      <div className="d-flex">
        <Button
          type="button"
          onClick={() =>
            apiWishDone(uuid, (data: any) => apiSuccsess(data, uuid), apiError)
          }
        >
          Подтвердить
        </Button>
        <Button
          type="button"
          white
          onClick={() => dispatch(action.mWishDoneSet(""))}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
};
