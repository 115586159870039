import React from "react";
import { Field, Form } from "components/form";
import {
  InputText,
  SelectDefault,
  FilterCity,
  SelectHash,
} from "components/input";
import { Button } from "components/button";

export const FormWishUser = ({
  contractStatus,
  categoryList,
  categoryDefault,
  performersDefault,
  tagsDefObj,
  cityDefault,
  wishRefuse,
  wishDone,
}: any) => {
  const disabled = true;
  const canRefuse = contractStatus === "new";
  const canDone = contractStatus === "new";

  return (
    <Form className="form form-wish-profile">
      <div className="form-wish-profile__container">
        <div className="row">
          <div className="col-12 mb-3">
            <InputText
              label="Название"
              name="wishName"
              placeholder="Введи название"
              role="formWishCreateName"
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <InputText
              as="textarea"
              label="Описание"
              name="wishDesc"
              placeholder="Введи описание"
              role="formWishCreateDescr"
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <SelectDefault
              name="wishCategory"
              label="Категория"
              placeholder="Выбери категорию"
              id="formWishCreateCategory"
              options={categoryList}
              defaultValue={categoryDefault}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <SelectHash
              name="tags"
              label="Хэштеги"
              placeholder="Например, “рыбалка”, “поход”"
              id="formWishEditTags"
              options={[]}
              customTags={[]}
              defaultValue={tagsDefObj}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <Field name="city">
              {({ field }: any) => (
                <FilterCity
                  name="city"
                  label="Место"
                  placeholder="Любой город"
                  id="formWishCreateCity"
                  defaultValue={cityDefault}
                  disabled={disabled}
                  onChange={(e: string) => {
                    const event: any = {
                      target: {
                        name: "city",
                        value: e,
                      },
                    };
                    field.onChange(event);
                  }}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-2">
            <SelectDefault
              name="memberAmount"
              label="Кол-во исполнителей"
              placeholder="Кол-во"
              id="formWishCreateMemberCount"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
                {
                  value: "5",
                  label: "5",
                },
                {
                  value: "10",
                  label: "10",
                },
              ]}
              defaultValue={performersDefault}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <div className="d-flex mt-2">
        {canRefuse && (
          <Button
            border
            type="button"
            className="mx-auto form-wish-profile__act-button"
            onClick={() => wishRefuse()}
          >
            Отказаться
          </Button>
        )}

        {canDone && (
          <Button
            type="button"
            className="mx-auto form-wish-profile__act-button"
            onClick={() => wishDone()}
          >
            Нажми, если исполнил
          </Button>
        )}
      </div>
    </Form>
  );
};
