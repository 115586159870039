import React, { useEffect } from "react";
import { useDsp, useSlc, RootState } from "app/store";
import { SectionMain } from "blocks/main";
import { apiGetCatalog, apiWishPerform } from "app/api";
import {
  useApiMainDashboard,
  useApiMainBirthday,
  useApiMainFaq,
  useApiChatSecret,
} from "app/hooks";
import { path } from "ramda";
import { useNavigate } from "react-router-dom";

export const WrapperMain = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();
  const reload = useSlc((state: RootState) => state.reloadMain);

  // SECTION DATA
  const faq: any = useApiMainFaq().data;
  const dashboard: any = useApiMainDashboard({ reload }).data;
  const birthday: any = useApiMainBirthday({ reload }).data;
  const { catalogData } = useSlc((state: RootState) => state.catalogState);

  const loading: boolean = path(["loading"], birthday) || false;

  // CHAT
  const chatData: any = useApiChatSecret().data;
  const chatSecret = path(["data", "secret"], chatData) || "";
  const chatTopic = path(["data", "common_chat_uid"], chatData) || "";
  const update = Date.now();

  const performWish = (uuid: string) => {
    apiWishPerform(uuid, dispatch, navigate);
  };

  useEffect(() => {
    apiGetCatalog(dispatch);
  }, []);

  return (
    <SectionMain
      loading={loading}
      dashboard={dashboard}
      birthday={birthday}
      catalog={catalogData}
      faq={faq}
      performWish={performWish}
      //
      chatSecret={chatSecret}
      chatTopic={chatTopic}
      update={update}
    />
  );
};
