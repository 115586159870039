import React from "react";
import { Form } from "components/form";
import { InputText } from "components/input";
import ButtonText from "elements/button/ButtonText/ButtonText";
import Headline from "components/headline/Headline";
import { Button } from "elements/button";
import EasyTimer from "components/EasyTimer/EasyTimer";
import { action, useDsp, useSlc, RootState } from "app/store";

type props = {
  recoveryIsShow: boolean;
};

export const FormRec = ({ recoveryIsShow }: props) => {
  const [resend, setResend] = React.useState(false);
  const dispatch = useDsp();
  const recoveredEmail = useSlc((state: RootState) => state.recoveredEmail);

  return (
    <Form className="form mt-3">
      <ButtonText
        size="md"
        onClick={() => dispatch(action.authVariantSet("login"))}
      >
        &lt; Назад
      </ButtonText>
      {recoveryIsShow && (
        <>
          <Headline className="mt-3" variant="md">
            Восстановление пароля
          </Headline>
          <div className="form__text mt-2">
            <p>Введи адрес электоронной почты, указанной при регистрации.</p>
          </div>
          <div className="mt-3">
            <InputText
              label="E-mail"
              name="email"
              placeholder="Введи личный e-mail"
              role="formRecovEmail"
            />
          </div>
          <div className="mt-3">
            <Button className="mx-auto ms-sm-auto me-sm-0" type="submit">
              Отправить
            </Button>
          </div>
        </>
      )}

      {!recoveryIsShow && (
        <>
          <Headline className=" mt-3" variant="md">
            Проверь почту
          </Headline>
          <div className="form__text mt-2">
            <p>Мы отправили письмо с новым паролем на почту {recoveredEmail}</p>
            {/* <p>Перейди по ссылке из письма для завершения регистрации.</p> */}
          </div>
          <div className="form__text mt-3">
            <p>
              <b>Не можешь найти письмо?</b> Проверь папку «Спам». Если там
              пусто, отправь письмо еще раз или&nbsp;
              <button
                type="button"
                className="btn-reset"
                onClick={() => dispatch(action.mFeedbackShow())}
              >
                свяжись&nbsp;с&nbsp;нами
              </button>
            </p>
          </div>
          <div className="mt-4">
            {!resend && (
              <Button
                className="mx-auto ms-sm-auto me-sm-0"
                type="button"
                disabled
              >
                Отправить ещё раз через&nbsp;
                <EasyTimer
                  timeType={["minutes", "seconds"]}
                  startValue={30}
                  onComplete={() => {
                    setResend(true);
                  }}
                />
              </Button>
            )}
            {resend && (
              <Button
                className="mx-auto ms-sm-auto me-sm-0"
                type="button"
                onClick={() => setResend(false)}
              >
                Отправить письмо
              </Button>
            )}
          </div>
        </>
      )}
    </Form>
  );
};
