import React from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import AsyncSelect from "react-select/async";
import { apiCity } from "app/api";
import { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconEdit } from "assets/images/icon-edit.svg";

type props = {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  defaultValue: {
    value: string;
    label: string;
  };
  cssPrefix?: string;
  variant?: string;
  disabled?: boolean;
  onEditClick?: any;
  onBlur?: any;
  onChange?: any;
};

const NoOptionsMessage = (config: any) => (
  <components.NoOptionsMessage {...config}>
    <span>Введи название города</span>
  </components.NoOptionsMessage>
);

const LoadingMessage = (config: any) => (
  <components.LoadingMessage {...config}>
    <span>Поиск...</span>
  </components.LoadingMessage>
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <></>
  </components.DropdownIndicator>
);

const LoadingIndicator = () => <span></span>;

const Menu = (props: any) => {
  const count = props.options.length;
  if (count < 4)
    return <components.Menu {...props}>{props.children}</components.Menu>;
  return (
    <components.Menu {...props}>
      <Scrollbar>{props.children}</Scrollbar>
    </components.Menu>
  );
};

export const SelectEditCity = ({
  id,
  name,
  label,
  placeholder,
  defaultValue,
  cssPrefix = "form-select",
  variant,
  disabled = false,
  onEditClick,
  onBlur,
  onChange,
}: props) => (
  <Field name={name}>
    {({ field }: any) => (
      <BSform.Group className="form-group">
        {label && <BSform.Label>{label}</BSform.Label>}

        <div className="form-control-relative-wrapper">
          <AsyncSelect
            // menuIsOpen={true}
            id={id}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            components={{
              NoOptionsMessage,
              LoadingMessage,
              LoadingIndicator,
              DropdownIndicator,
              Menu,
            }}
            className="form-select-container"
            classNamePrefix={cssPrefix}
            isDisabled={disabled}
            cacheOptions
            loadOptions={apiCity}
            captureMenuScroll={false}
            menuShouldScrollIntoView={false}
            onChange={(e: any) => {
              // console.log("SELECT СITY", e);
              const event = {
                target: {
                  name,
                  value: e,
                },
              };
              field.onChange(event);
              if (onBlur) onBlur();
              // if (onChange) onChange(e);
            }}
            onBlur={() => {
              if (onBlur) onBlur();
            }}
          />

          {variant === "edit" && (
            <button
              type="button"
              className={`btn-reset form-control-edit-button ${
                disabled ? "disabled" : ""
              }`}
              onClick={() => onEditClick()}
            >
              <IconEdit />
            </button>
          )}
        </div>

        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </BSform.Group>
    )}
  </Field>
);
