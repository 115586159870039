import { urls } from "app/constants";
import { action } from "app/store";
import { axiosSecure } from "./_api";

export const apiGetCatalog = (dispatch: any) => {
  // LOADER ON
  dispatch(
    action.catalogSetState({
      catalogLoading: true,
      catalogData: null,
      catalogError: null,
    })
  );

  axiosSecure({
    url: urls.wishList,
    method: "get",
  })
    .then(({ status, data }: any) => {
      // SUCCESS => LOADER OFF, DATA
      if (status === 200 && data) {
        dispatch(
          action.catalogSetState({
            catalogLoading: false,
            catalogData: data,
            catalogError: null,
          })
        );
      }
    })
    .catch((error: any) => {
      // ERROR => LOADER OFF, ERROR
      dispatch(
        action.catalogSetState({
          catalogLoading: false,
          catalogData: null,
          catalogError: error,
        })
      );
    });
};
