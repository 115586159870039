import React from "react";
import { action, useDsp } from "app/store";
import { Formik, FormActivate } from "components/form";
import { schemas, values } from "app/utils/schemas";
import { apiActivate } from "app/api";

export const WrapperActive = () => {
  const dispatch = useDsp();

  const apiSuccess = ({ data, status }: any, email: string) => {
    // console.log("API ACTIVATION SUCCESS", data);

    if (status === 201) {
      dispatch(action.activatedEmailSet(email));
      dispatch(action.activationIsShowSet(false));
      dispatch(action.activatedResendSet(false));
    }
  };

  const apiError = (err: any) => {
    // console.log("API ACTIVATION ERROR", err);
  };

  return (
    <Formik
      initialValues={values.rec}
      validationSchema={schemas.rec}
      onSubmit={(params, { setErrors }) =>
        apiActivate(
          params,
          (data: any) => apiSuccess(data, params.email),
          apiError,
          setErrors
        )
      }
    >
      <FormActivate />
    </Formik>
  );
};
