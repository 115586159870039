import React, { useEffect, useState } from "react";
import { apiUserRating } from "app/api";
import { useSlc, action, useDsp, RootState } from "app/store";
import { SectionRating } from "blocks/rating";

export const WrapperUserRating = () => {
  const dispatch = useDsp();
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState({});

  const filterCity = useSlc((state: RootState) => state.fRatingCity);
  const filterPeriod = useSlc((state: RootState) => state.fRatingPeriod);
  const pagination = useSlc((state: RootState) => state.fRatingPagination);
  const filterCount = 5;

  type apiParameters = {
    city: string;
    period: string;
    page: number;
    perPage: number;
  };

  const params: apiParameters = {
    city: filterCity.value,
    period: filterPeriod.value,
    page: pagination.page,
    perPage: filterCount,
  };

  const onPaginatorClick = (obj: any) => {
    dispatch(action.fRatingPaginationSet(obj));
  };

  const apiSuccess = ({ status, data }: any) => {
    console.log("API USER RATING SUCCESS", data);
    if (status === 200 && data) {
      setRating(data);
      dispatch(
        action.fRatingPaginationSet({
          page: params.page || 1,
          count: data.count,
        })
      );
      setLoading(false);
    }
  };

  const apiError = (err: any) => {
    // console.log("API USER RATING ERROR", err);
    setLoading(false);
  };

  useEffect(() => {
    apiUserRating(params, apiSuccess, apiError);
    setLoading(true);
  }, [filterCity.value, filterPeriod.value, pagination.page]);

  return (
    <SectionRating
      loading={loading}
      rating={rating}
      filterCount={filterCount}
      pagination={pagination}
      onPaginatorClick={onPaginatorClick}
    />
  );
};
