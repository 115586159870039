import { createAction, createReducer } from "@reduxjs/toolkit";

const wishCategoriesSet = createAction<any>("wishCategoriesSet");

const wishCategories = createReducer([], (builder) => {
  builder.addCase(wishCategoriesSet, (state, action) => action.payload);
});

export const actionWishCategories = {
  wishCategoriesSet,
};

export const reducerWishCategories = {
  wishCategories,
};
