import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { LoaderMini } from "components/loader";
import "./modalProcess.scss";

export const ModalProcess = () => {
  const dispatch = useDsp();
  const data = useSlc((state: RootState) => state.mProcessData);
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mProcess)}
      onHide={() => dispatch(action.mInfoHide())}
      title={data.title}
    >
      <div className="modal-process__loader">
        <LoaderMini />
      </div>
      <div className="modal-info__message">{data.message}</div>
    </ModalBasic>
  );
};
