import React, { useState } from "react";
import { Formik, FormProfileUser } from "components/form";
import { apiSetProfile } from "app/api";
import { schemas } from "app/utils/schemas";
import { store, action, useDsp } from "app/store";

export const WrapperProfileUser = () => {
  const dispatch = useDsp();

  const [isLoading, setIsLoading] = useState(false);
  const [isCityEditable, setCityEditable] = useState(false);
  const [isDateEditable, setDateEditable] = useState(false);
  const [isDescEditable, setDescEditable] = useState(false);
  const [isPhoneEditable, setPhoneEditable] = useState(false);
  const [isTelegaEditable, setTelegaEditable] = useState(false);

  const setFocus = (sel: string) => {
    const element: HTMLInputElement | null = document.querySelector(sel);
    if (element) setTimeout(() => element.focus(), 500);
  };

  const onCityEditClick = () => {
    if (
      !isLoading &&
      !isCityEditable &&
      !isDateEditable &&
      !isDescEditable &&
      !isPhoneEditable &&
      !isTelegaEditable
    ) {
      setCityEditable(true);
      setFocus(".form-select-edit__input");
    }
  };

  const onDateEditClick = () => {
    if (
      !isLoading &&
      !isCityEditable &&
      !isDateEditable &&
      !isDescEditable &&
      !isPhoneEditable &&
      !isTelegaEditable
    ) {
      setDateEditable(true);
      setFocus(".form-profile-user__form-control");
    }
  };

  const onDescEditClick = () => {
    if (
      !isLoading &&
      !isCityEditable &&
      !isDateEditable &&
      !isDescEditable &&
      !isPhoneEditable &&
      !isTelegaEditable
    ) {
      setDescEditable(true);
      setFocus(".form-profile-user__textarea");
    }
  };

  const onPhoneEditClick = () => {
    if (
      !isLoading &&
      !isCityEditable &&
      !isDateEditable &&
      !isDescEditable &&
      !isPhoneEditable &&
      !isTelegaEditable
    ) {
      setPhoneEditable(true);
      setFocus(".form-control--phone");
    }
  };

  const onTelegaEditClick = () => {
    if (
      !isLoading &&
      !isCityEditable &&
      !isDateEditable &&
      !isDescEditable &&
      !isPhoneEditable &&
      !isTelegaEditable
    ) {
      // console.log("11111");
      setTelegaEditable(true);
      setFocus(".form-control--telega");
    }
  };

  const apiSuccess = (data: any, params: any) => {
    // console.log("API SET PROFILE SUCCESS", data);
    // console.log("API SET PROFILE SUCCESS", params);
    if (data.status === 201) {
      setCityEditable(false);
      setDateEditable(false);
      setDescEditable(false);
      setPhoneEditable(false);
      setTelegaEditable(false);
      setTimeout(() => setIsLoading(false), 100);
      //
      // console.log(params);
      dispatch(
        action.userSet({
          isAuth: true,
          name: store.getState().user.name,
          birthDay: params.date ? params.date : null,
          city: {
            name: params.city.label,
            uuid: params.city.value,
          },
          number: store.getState().user.number,
          description: params.description,
          email: "",
          image: store.getState().user.image,
          phone: params.phone || store.getState().user.phone,
          telega: params.telega || store.getState().user.telega,
        })
      );
    }
  };

  const apiError = (err: any) => {
    setCityEditable(false);
    setDateEditable(false);
    setDescEditable(false);
    setPhoneEditable(false);
    setTelegaEditable(false);
    setTimeout(() => setIsLoading(false), 100);
  };

  return (
    <Formik
      initialValues={{
        city: {
          value: store.getState().user.city.uuid,
          label: store.getState().user.city.name,
        },
        date: store.getState().user.birthDay,
        description: store.getState().user.description,
        phone: store.getState().user.phone,
        telega: store.getState().user.telega,
      }}
      validationSchema={schemas.profile}
      onSubmit={(params, { setErrors }) => {
        setIsLoading(true);
        apiSetProfile(
          params,
          (data: any) => apiSuccess(data, params),
          apiError,
          setErrors
        );
      }}
    >
      <FormProfileUser
        isCityEditable={isCityEditable}
        isDateEditable={isDateEditable}
        isDescEditable={isDescEditable}
        isPhoneEditable={isPhoneEditable}
        isTelegaEditable={isTelegaEditable}
        //
        onCityEditClick={onCityEditClick}
        onDateEditClick={onDateEditClick}
        onDescEditClick={onDescEditClick}
        onPhoneEditClick={onPhoneEditClick}
        onTelegaEditClick={onTelegaEditClick}
        //
      />
    </Formik>
  );
};
