import React, { useEffect } from "react";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { apiGlobalChat } from "app/api";

export const GlobalChatRedirect = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  const apiSuccess = ({ data, status }: any) => {
    console.log("API GALLERY SUCCESS", data);
    if (status === 200 && data && data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  };

  const apiError = () => {
    console.log("API GALLERY ERROR");
  };

  useEffect(() => {
    apiGlobalChat(apiSuccess, apiError);
  }, []);

  if (isAuth) return <div></div>;
  return <RedirectTo path="/sign-in" />;
};
