import React from "react";
import BirthdayWishlist from "blocks/birthdayWishlist/birthdayWishlist";
import Intro from "blocks/intro/intro";
import NewWishlist from "blocks/newWishlist/newWishlist";
import QuickCategories from "blocks/quickCategories/quickCategories";
import Faq from "blocks/faq/faq";
import { ChatWidget, ChatExtend } from "components/chat";
import { LoaderMini } from "components/loader";

type props = {
  loading: boolean;
  dashboard: any[];
  birthday: any[];
  catalog: any;
  faq: any[];
  performWish: any;
  chatSecret: any;
  chatTopic: any;
  update: number;
};

export const SectionMain = ({
  loading,
  dashboard,
  birthday,
  faq,
  catalog,
  performWish,
  //
  chatSecret,
  chatTopic,
  update,
}: props) => {
  if (loading)
    return (
      <section className="main bg-decoration">
        <LoaderMini />
      </section>
    );

  return (
    <section className="main bg-decoration">
      <div className="bg-decoration__wrapper bg-decoration__wrapper_main" />
      <Intro />
      <NewWishlist dashboard={dashboard} performWish={performWish} />
      <BirthdayWishlist birthday={birthday} performWish={performWish} />
      <QuickCategories catalog={catalog} />
      <Faq faq={faq} />
      <ChatWidget />
      <ChatExtend
        chatSecret={chatSecret}
        chatTopic={chatTopic}
        update={update}
      />
    </section>
  );
};
