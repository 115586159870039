import { useEffect, useState } from "react";
import { axiosSecure } from "app/api";

type props = {
  url: string;
  method: string;
  params?: any;
  reload?: number;
};

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const useApi = ({ url, method, params, reload }: props) => {
  // console.log("START USE API");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError]: any = useState(null);

  const makeRequest = () => {
    axiosSecure({
      url: baseurl + url,
      method,
      data: params,
    })
      .then((data: any) => {
        // console.log("SUCCESS");
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        // console.log("ERROR");
        setLoading(false);
      });
  };

  useEffect(() => {
    makeRequest();
  }, [reload]);

  return { loading, data, error };
};
