import React from "react";
import Headline from "components/headline/Headline";
import Accordion from "react-bootstrap/Accordion";
import { path } from "ramda";

type props = {
  faq: any[];
};

const Faq = ({ faq }: props) => {
  const data: any = path(["data"], faq) || [];

  return (
    <div className="faq" id="faskedquestions">
      <div className="container">
        <Headline className="headline_lg faq__headline">
          Полезная информация
        </Headline>
        <Accordion className="faq__accordion">
          {data.map((el: any) => {
            const question = el.text || "";
            const answer =
              (el.answers && el.answers[0] && el.answers[0].text) || "";

            return (
              <Accordion.Item eventKey={el.number} key={el.number}>
                <Accordion.Header>
                  <span className="accordion-button-chevron">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 10 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="m1.687 0 8 8-8 8L0 14.313 6.313 8 0 1.687 1.687 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="accordion-button-title">{question}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: answer }} />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
