import { createAction, createReducer } from "@reduxjs/toolkit";

const catalogSetState = createAction<any>("catalogSetState");

const catalogState = createReducer(
  { catalogLoading: true, catalogData: null, catalogError: null },
  (builder) => {
    builder.addCase(catalogSetState, (state, action) => action.payload);
  }
);

export const actionCatalog = {
  catalogSetState,
};

export const reducerCatalog = {
  catalogState,
};
