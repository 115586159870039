import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import SwiperCore, { Navigation, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as IconArrowLeft } from "assets/images/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";

export const ModalGalleryView = () => {
  const dispatch = useDsp();
  const startFrom = useSlc((state: RootState) => state.mGalleryViewNumber) || 0;
  const photos = useSlc((state: RootState) => state.galleryList) || [];
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const bigPhotos = photos.map(({ original, small }: any) => ({
    src: baseurl + original.url,
    srcSmall: baseurl + small.url,
    width: original.width,
    height: original.height,
  }));

  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mGalleryView)}
      onHide={() => dispatch(action.mGalleryViewHide())}
      title=""
      dialogClassName="modal-dialog--gallery-view"
      contentClassName="modal-content--gallery-view"
      bodyClassName="modal-body--gallery-view"
    >
      <Swiper
        className="gallery-slider__list"
        modules={[Navigation]}
        initialSlide={startFrom}
        spaceBetween={4}
        slidesPerView="auto"
        slideToClickedSlide={false}
        lazy={true}
        preloadImages={false}
        navigation={{
          prevEl: ".gallery-slider__arrow--prev",
          nextEl: ".gallery-slider__arrow--next",
        }}
      >
        <button type="button" className="btn-reset gallery-slider__arrow--prev">
          <IconArrowLeft />
        </button>
        <button type="button" className="btn-reset gallery-slider__arrow--next">
          <IconArrowRight />
        </button>
        {bigPhotos.map((el: any, i: number) => (
          <SwiperSlide key={i} className="gallery-slide">
            <LazyLoadImage
              effect="blur"
              className="gallery-slide__image"
              src={el.src}
              placeholderSrc={el.srcSmall}
            />
            {/* <img
              className="swiper-lazy gallery-slide__image"
              data-src={el.src}
              alt=""
            /> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </ModalBasic>
  );
};
