import { urls } from "app/constants";
import { axiosBase } from "./_api";

export const apiCity = async (letters: string) => {
  const newRequest = axiosBase({
    method: "GET",
    url: urls.city(letters),
  });

  return newRequest.then(({ data, status }: any) => {
    if (status === 200 && data) {
      return data.map((city: any) => ({
        label: city.name,
        value: city.uuid,
        subject: city.subject,
      }));
    }
    return [];
  });
};
