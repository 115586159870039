import React from "react";
import { store, action, useDsp } from "app/store";
import { Formik, FormWishCreate } from "components/form";
import { schemas, values } from "app/utils/schemas";
import { apiWishCreate } from "app/api";
import { useApiCategories } from "app/hooks";

export const WrapperWishCreate = () => {
  const dispatch = useDsp();
  const wishCategories: any = useApiCategories().data;

  let categoryList = [];
  if (
    wishCategories &&
    wishCategories.status === 200 &&
    wishCategories.data &&
    wishCategories.data.length > 0
  ) {
    categoryList = wishCategories.data.map((l: any) => ({
      label: l.name,
      value: l.slug,
    }));
  }

  const apiSuccess = ({ status, data }: any) => {
    // console.log("API WISH CREATE SUCCESS");
    if (status === 201 && data) {
      dispatch(action.mWishCreateHide());

      dispatch(
        action.mInfoSetData({
          title: "Благодарим за созданный виш",
          message: `Сейчас он проходит модерацию. После этого его увидят твои коллеги. Также ты его сможешь найти в разделе "Мои виши" личного кабинета.`,
        })
      );
      dispatch(action.mInfoShow());

      //
      const currWishes = store.getState().dataProfileWishes;
      dispatch(action.dataProfileWishesSet([data, ...currWishes]));
    }
  };
  const apiError = () => {
    // console.log("API WISH CREATE ERROR");
  };

  return (
    <Formik
      initialValues={values.wishCreate}
      validationSchema={schemas.wishCreate}
      onSubmit={(params, { setErrors }) => {
        apiWishCreate(params, apiSuccess, apiError, setErrors);
      }}
    >
      <FormWishCreate categoryList={categoryList} />
    </Formik>
  );
};
