export const dtoWishCreate = (data: any) => {
  // console.log("DTO WISH CREATE", data);
  return {
    title: data.wishName,
    description: data.wishDesc,
    category: data.wishCategory,
    tags: data.tags,
    city: data.city,
    max_performer_amount: data.memberAmount,
  };
};

export const dtoWishlists = (param: any) => {
  // console.log("DTO WISHLISTS IN", param);
  const obj: any = {};
  if (param.city) obj.city = param.city;
  if (param.name) obj.q = param.name;
  return obj;
};

export const dtoWishPaginator = (param: any) => {
  // console.log("DTO WISHLISTS IN", param);
  const obj: any = {};
  if (param.city) obj.city = param.city;
  if (param.name) obj.q = param.name;
  if (param.perPage) obj.per_page = param.perPage;
  if (param.page) obj.page = param.page || 1;
  return obj;
};

export const dtoWishEdit = (data: any) => {
  // console.log("DTO WISH EDIT", data);
  return {
    title: data.wishName,
    description: data.wishDesc,
    category: data.wishCategory.value,
    tags: data.tags,
    city: data.city.value,
    max_performer_amount: data.memberAmount.value,
  };
};
