import React, { useEffect } from "react";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { Button } from "components/button";
import { ReactComponent as IconGallery } from "assets/images/icon-gallery.svg";
import PhotoAlbum from "react-photo-album";
import { apiGallery } from "app/api";
import { LinkActionBack } from "components/link";
import { RenderPhoto } from "./_renderPhoto";

export const Gallery = () => {
  const dispatch = useDsp();
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  const photos = useSlc((state: RootState) => state.galleryList) || [];
  console.log("PHOTOS", photos);
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const smallPhotos = photos.map(({ id, original, small }: any) => ({
    alt: id,
    src: baseurl + original.url,
    width: original.width,
    height: original.height,
    title: baseurl + small.url,
  }));
  // console.log("GALLERY", smallPhotos);

  const apiSuccess = ({ data, status }: any) => {
    // console.log("API GALLERY SUCCESS");
    if (status === 200 && data) {
      dispatch(action.galleryListSet(data));
    }
  };

  const apiError = (error: any) => {
    // console.log("API GALLERY ERROR");
  };

  useEffect(() => {
    apiGallery(apiSuccess, apiError);
  }, []);

  if (isAuth)
    return (
      <section className="gallery" role="sectionGallery">
        <div className="gallery__wrapper">
          <LinkActionBack to="/profile">Назад</LinkActionBack>
          <div className="gallery__container">
            <div className="gallery__header">
              <div className="gallery__header-icon">
                <IconGallery />
              </div>
              <div className="gallery__header-content">
                <h2 className="gallery__header-title">Моя галерея</h2>
                <p className="gallery__header-subtitle">
                  Мои фотографии{" "}
                  <span>{smallPhotos && smallPhotos.length}</span>
                </p>
              </div>
              <Button
                className="gallery__header-button"
                type="button"
                onClick={() => dispatch(action.mGalleryShow())}
              >
                Добавить фото
              </Button>
            </div>

            <div className="gallery__body">
              <div className="gallery__images">
                <PhotoAlbum
                  layout="rows"
                  photos={smallPhotos}
                  onClick={(event, photo, index) => {
                    console.log(index);
                    dispatch(action.mGalleryViewNumberSet(index));
                    dispatch(action.mGalleryViewShow());
                  }}
                  renderPhoto={RenderPhoto}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  return <RedirectTo path="/sign-in" />;
};
