import React from "react";
import { BSform } from "components/form";
import AsyncSelect from "react-select/async";
import { useSlc, action, useDsp, RootState } from "app/store";
import { apiCity } from "app/api";
import { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconDefault } from "assets/images/icon-select-arrow.svg";

type props = {
  id?: string;
  name: string;
  label?: string;
  placeholder: string;
  cssPrefix?: string;
  className?: string;
  variant?: string;
  disabled?: boolean;
  defaultOptions?: any;
  defaultValue?: any;
  onChange: any;
};

const NoOptionsMessage = (config: any) => (
  <components.NoOptionsMessage {...config}>
    <span>Введи название города</span>
  </components.NoOptionsMessage>
);

const LoadingMessage = (config: any) => (
  <components.LoadingMessage {...config}>
    <span>Поиск...</span>
  </components.LoadingMessage>
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <IconDefault />
  </components.DropdownIndicator>
);

const LoadingIndicator = () => <span></span>;

const Menu = (props: any) => {
  const count = props.options.length;
  if (count < 4)
    return (
      <components.Menu className="autoheight" {...props}>
        {props.children}
      </components.Menu>
    );
  return (
    <components.Menu {...props}>
      <Scrollbar>{props.children}</Scrollbar>
    </components.Menu>
  );
};

export const FilterCity = ({
  id,
  name,
  label,
  placeholder,
  cssPrefix = "form-select",
  className,
  disabled,
  defaultOptions,
  defaultValue,
  onChange,
}: props) => {
  const dispatch = useDsp();

  return (
    <BSform.Group className="form-group">
      {label && <BSform.Label>{label}</BSform.Label>}

      <div className="form-control-relative-wrapper">
        <AsyncSelect
          // menuIsOpen={true}
          id={id}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          components={{
            NoOptionsMessage,
            LoadingMessage,
            LoadingIndicator,
            DropdownIndicator,
            Menu,
          }}
          isDisabled={disabled}
          className={className}
          classNamePrefix={cssPrefix}
          cacheOptions
          loadOptions={apiCity}
          defaultOptions={defaultOptions}
          captureMenuScroll={false}
          menuShouldScrollIntoView={false}
          onChange={(e: any) => {
            // console.log("SELECT СITY", e);
            if (onChange) onChange(e);
          }}
        />
      </div>
    </BSform.Group>
  );
};
