import React, { useState, useEffect } from "react";
import { InputFilter, FilterCity, SelectFilter } from "components/input";
import { Button, ButtonModalClose } from "elements/button";
import { store, useSlc, action, useDsp, RootState } from "app/store";
import clsx from "clsx";

export const FormWishListFilter = ({ counter, className }: any) => {
  const dispatch = useDsp();
  const [inputData, setInputData] = useState("");
  const filterIsShow = useSlc((state: RootState) => state.fWishListIsShow);
  const defaultCity = useSlc((state: RootState) => state.fWishListsCity);
  const { value } = store.getState().fWishListsName;

  const filterHide = () => {
    document.body.style.overflow = "";
    dispatch(action.fWishListHide());
    dispatch(action.fWishListsNameSet({ value: inputData }));
  };

  const searchHandler = () => {
    dispatch(
      action.fWishPaginationSet({
        page: 1,
        count: 1,
      })
    );
    dispatch(action.fWishListsNameSet({ value: inputData }));
  };

  useEffect(() => {
    setInputData(value);
  }, []);

  return (
    <form
      className={
        filterIsShow
          ? clsx("wishlist-filter wishlist-filter--show", className)
          : "wishlist-filter"
      }
    >
      <ButtonModalClose onClick={filterHide} />
      <div className="wishlist-filter__search">
        <InputFilter
          inputData={inputData}
          setInputData={setInputData}
          className="wishlist-filter__search-field"
          name="search"
          placeholder="Введи имя коллеги или хэштег"
          onSearchClick={searchHandler}
        />
      </div>
      <div className="wishlist-filter__city-selector">
        <FilterCity
          className="form-select-filter-container"
          name="city"
          placeholder="Выбери город"
          defaultOptions={[
            {
              value: "",
              label: "Все города",
            },
          ]}
          defaultValue={defaultCity}
          onChange={(e: string) => {
            dispatch(
              action.fWishPaginationSet({
                page: 1,
                count: 1,
              })
            );
            dispatch(action.fWishListsCitySet(e));
          }}
        />
      </div>

      {counter && (
        <div className="wishlist-filter__count-selector">
          <SelectFilter
            name="count"
            placeholder="Вид: 12"
            onChange={(e: any) => {
              const count = parseInt(e.value, 10);
              dispatch(action.fWishListsCountSet(e));
              dispatch(
                action.fWishPaginationSet({
                  page: 1,
                  count,
                })
              );
            }}
            defaultValue={useSlc((state: RootState) => state.fWishListsCount)}
            options={[
              {
                value: "12",
                label: "Вид: 12",
              },
              {
                value: "24",
                label: "Вид: 24",
              },
              {
                value: "48",
                label: "Вид: 48",
              },
            ]}
          />
        </div>
      )}

      <Button className="wishlist-filter__apply-button" onClick={filterHide}>
        Применить изменения
      </Button>
    </form>
  );
};
