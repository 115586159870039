import React from "react";
import iconAvatar from "assets/images/avatar-default-big.svg";
import { ReactComponent as IconEdit } from "assets/images/icon-edit.svg";
import { store, action, useDsp, useSlc, RootState } from "app/store";

type props = {
  onInput: any;
};

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const FormProfileAvatar = ({ onInput }: props) => {
  const user = useSlc((state: RootState) => state.user);

  const avatar = user.image ? baseurl + user.image : "";

  return (
    <form className="form-profile-avatar" role="formProfileAvatar">
      <label className="form-profile-avatar__label">
        <input
          type="file"
          name="avatar"
          onInput={(event: any) => {
            onInput(event);
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
        />
        <img src={avatar || iconAvatar} alt="Avatar" />
        <span>
          <IconEdit />
        </span>
      </label>
    </form>
  );
};
