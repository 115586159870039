import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import { Button } from "components/button";
import imageIntro1 from "assets/images/image-information-1@2x.jpg";
import imageIntro1Mob from "assets/images/image-information-1-mob@2x.jpg";
import imageIntro2 from "assets/images/image-information-2@2x.jpg";
import imageIntro2Mob from "assets/images/image-information-2-mob@2x.jpg";
import imageIntro4 from "assets/images/image-information-4@2x.jpg";
import imageIntro4Mob from "assets/images/image-information-4-mob@2x.jpg";
import imageIntro5 from "assets/images/image-information-5@2x.jpg";
import imageIntro5Mob from "assets/images/image-information-5-mob@2x.jpg";
import imageIntro6 from "assets/images/image-information-6@2x.jpg";
import imageIntro6Mob from "assets/images/image-information-6-mob@2x.jpg";
import iconArrow from "assets/images/iconIntroArrow.svg";
import imageAddWish from "assets/images/image-information-add-wish@2x.png";

export const ModalIntro = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mIntro)}
      onHide={() => dispatch(action.mIntroHide())}
      className="modal--intro"
      dialogClassName="modal-dialog--intro"
      contentClassName="modal-content--intro"
      bodyClassName="modal-body--intro"
      backdrop="static"
    >
      <Swiper
        className="swiper-intro"
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView="auto"
        slideToClickedSlide
        navigation={{
          prevEl: ".new--wishlist__arrow--prev",
          nextEl: ".new--wishlist__arrow--next",
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "active",
          bulletClass: "swiper--dots__item",
          el: ".new--wishlist__dots",
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        }}
      >
        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-1">
          <picture>
            <source srcSet={imageIntro1} media="(min-width: 769px)" />
            <source srcSet={imageIntro1Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro1}
              alt=""
            />
          </picture>

          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Привет, меня зовут «Кружок», а это – платформа помощи, поддержки
              и&nbsp;общения!
            </h3>
            <p className="swiper-intro__text">
              Здесь каждый сотрудник сможет найти единомышленника для обмена
              опытом, передачи экспертизы и&nbsp;просто совместного отдыха.
            </p>
            <p className="swiper-intro__text">
              В «Кружке» нет коммерческих предложений или платных услуг – все
              виши исполняются безвозмездно, то есть даром.
            </p>
            <p className="swiper-intro__text">
              <b>Потому что МегаФон — больше, чем коллеги!</b>
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-2">
          <picture>
            <source srcSet={imageIntro2} media="(min-width: 769px)" />
            <source srcSet={imageIntro2Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro2}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Пройди регистрацию и заполни профиль.
            </h3>
            <p className="swiper-intro__text">
              Укажи информацию о себе, поделись интересами и увлечениями,
              обязательно загрузи фотографию, чтобы коллеги могли узнать тебя. В
              профиле будут отображены твои виши и виши коллег, которые можешь
              исполнить.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-3">
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Если ты хочешь, чтобы коллеги помогли тебе в чём-то или разделили
              с тобой классный опыт, просто создай виш!
            </h3>
            <div className="swiper-intro__step-1">
              <p>1. Нажми кнопку «Создать виш».</p>
              <img src={iconArrow} alt="" />
              <Button
                onClick={() => {
                  dispatch(action.mIntroHide());
                  dispatch(action.mWishCreateShow());
                }}
              >
                Создать виш
              </Button>
            </div>
            <div className="swiper-intro__step-2">
              <img src={imageAddWish} alt="" />
              <p>
                2. Заполни карточку виша: название, описание, подходящую
                категорию, город, срок исполнения и количество человек, которые
                могут его исполнить
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-4">
          <picture>
            <source srcSet={imageIntro4} media="(min-width: 769px)" />
            <source srcSet={imageIntro4Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro4}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h2 className="swiper-intro__h2">
              Когда виш пройдёт модерацию, его увидят коллеги
            </h2>
            <p className="swiper-intro__text">
              Он отобразится в соответствующей рубрике. Виш могут взять в работу
              один или несколько человек, если это предложение о совместном
              опыте. Виш действителен до конца года.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-5">
          <picture>
            <source srcSet={imageIntro5} media="(min-width: 769px)" />
            <source srcSet={imageIntro5Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro5}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Если ты захочешь выполнить чужой виш, просто зайди в интересующую
              рубрику и выбери понравившийся виш.
            </h3>
            <p className="swiper-intro__text">
              Затем нажми кнопку «Исполнить» и на портале откроется чат с
              автором виша, где вы можете обсудить детали. Когда твой виш будет
              реализован, просто поменяй его статус на «Выполнен».
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-6">
          <picture>
            <source srcSet={imageIntro6} media="(min-width: 769px)" />
            <source srcSet={imageIntro6Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro6}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content swiper-intro__content-6">
            <h3 className="swiper-intro__h3">
              Остались вопросы? Открой раздел «Обратная связь» и напиши нам на
              почту.
            </h3>
            <Button
              className="swiper-intro__button-6"
              onClick={() => {
                dispatch(action.mIntroHide());
                dispatch(action.mFeedbackShow());
              }}
            >
              Обратная связь
            </Button>
          </div>
        </SwiperSlide>

        <SwiperNavigation
          className="swiper-intro__navigation"
          arrowPrev="new--wishlist__arrow--prev"
          arrowNext="new--wishlist__arrow--next"
          dots="new--wishlist__dots"
        />
      </Swiper>
    </ModalBasic>
  );
};
