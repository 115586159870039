import React from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { ModalBasic } from "components/modal";
import { Button } from "components/button";
import { apiWishPerform } from "app/api";
import { useNavigate } from "react-router-dom";

export const ModalWishDetails = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();
  const data = useSlc((state: RootState) => state.mWishDetailsData);

  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mWishDetails)}
      onHide={() => dispatch(action.mWishDetailsHide())}
      title={data.title}
    >
      <div className="row">
        <div className="col-12 mb-2">
          <div className="modal-wish-label">Название</div>
          <div className="modal-wish-detail">{data.name}</div>
        </div>
        <div className="col-12 mb-2">
          <div className="modal-wish-label">Описание</div>
          <div className="modal-wish-detail">{data.description}</div>
        </div>
        <div className="col-12 mb-2">
          <div className="modal-wish-label">К чему относится</div>
          <div className="modal-wish-detail">{data.category}</div>
        </div>
        <div className="col-12 mb-2">
          <div className="modal-wish-label">Город</div>
          <div className="modal-wish-detail">{data.city}</div>
        </div>
        <div className="col-12 mb-4">
          <div className="modal-wish-label">Кол-во исполнителей</div>
          <div className="modal-wish-detail">{data.maxPerformers}</div>
        </div>
        <div className="col-12">
          {data.status && data.status === "published" && (
            <Button
              type="button"
              className="mx-auto"
              onClick={() => apiWishPerform(data.uuid, dispatch, navigate)}
            >
              Исполнить
            </Button>
          )}
        </div>
      </div>
    </ModalBasic>
  );
};
