import React from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { Chat } from "../chat/chat";

export const ChatExtend = ({ chatSecret, chatTopic, update }: any) => {
  const chatExtendIsActive = useSlc((state: RootState) => state.chatExstend);
  const dispatch = useDsp();
  const chatShow = () => {
    document.body.style.overflow = "hidden";
    dispatch(action.chatExstendShow());
  };

  return (
    <div
      className={`chat-extend ${chatExtendIsActive ? "" : "chat-extend-min"}`}
    >
      {chatSecret && chatTopic && (
        <Chat
          variant="extend"
          chatSecret={chatSecret}
          chatTopic={chatTopic}
          update={update}
          willConnect
        />
      )}

      <button
        type="button"
        className="btn-reset chat-widget__name"
        onClick={chatShow}
      >
        Общий чат
      </button>
    </div>
  );
};
