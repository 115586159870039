import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSlc, action, useDsp, RootState } from "app/store";
import { FormProfileWishesFilter } from "components/form";
import { useApiUserWishes } from "app/hooks";
import WishTile from "components/wishTile/WishTile";
import { Button } from "components/button";
import { LoaderMini } from "components/loader";
import ImageEmpty from "assets/images/image-profile-wishes-empty@2x.png";
import ImageMobileEmpty from "assets/images/image-mobile-profile-wishes-empty@2x-min.png";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
import { path } from "ramda";
import { LinkActionBack } from "components/link";

export const SectionUserWishes = ({ performWish }: any) => {
  const dispatch = useDsp();
  const { id }: any = useParams() || "";
  const reload = useSlc((state: RootState) => state.reloadMain);
  const { loading, data }: any = useApiUserWishes(id, reload);

  const wishList: any[] = path(["data"], data) || [];

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  return (
    <section
      className="profile-wishes profile-wishes--wishes bg-decoration"
      role="sectionProfileWishes"
    >
      <div className="bg-decoration__wrapper" />

      <div className="profile-wishes__wrapper">
        <div className="profile-wishes__menu-back">
          <LinkActionBack to={`/user/${id}`}>Назад</LinkActionBack>
        </div>

        <div className="profile__wishes">
          <div className="profile__card-icon">
            <IconStaf />
          </div>
          <h2 className="profile__card-title">Вишлист коллеги</h2>
          <p className="profile__card-subtitle">Помоги своему коллеге!</p>
          {/* <Button
              className="w-100 mt-3"
              type="button"
              onClick={() => dispatch(action.mWishCreateShow())}
            >
              Создать виш
            </Button> */}
        </div>

        <div className="profile-wishes__contaiter">
          {/* <FormProfileWishesFilter /> */}
          <div className="profile-wishes__tile-list">
            {wishList.map((el: any) => {
              const tags: any = [{ text: el.category.name, type: "" }];

              if (el.status === "done") {
                tags.unshift({
                  text: "Исполнен",
                  type: "done",
                });
              }

              // if (el.status === "published") {
              //   tags.unshift({
              //     text: "Ждет исполнителя",
              //     type: "await",
              //   });
              // }

              return (
                <WishTile
                  key={el.uuid}
                  className="wishlist-paginator__tile"
                  title={el.title}
                  avatar={el.author.image}
                  name={`${el.author.name}`}
                  location={el.author.city.name}
                  tags={tags}
                  hashtags={el.tags}
                  // BUTTONS
                  btnDetailShow
                  btnActionShow={el.status === "published"}
                  // btnDetailLink={`/user/wishes/${el.uuid}`}
                  linkToProfile={`/user/${el.author.uuid}`}
                  // ACTIONS
                  onDetailClick={() => {
                    dispatch(
                      action.mWishDetailsDataSet({
                        uuid: el.uuid,
                        title: "Виш коллеги",
                        name: el.title,
                        description: el.description,
                        category: el.category.name,
                        city: el.city.name,
                        maxPerformers: el.max_performer_amount,
                        status: el.status,
                        contracts: el.contracts,
                      })
                    );
                    dispatch(action.mWishDetailsShow());
                  }}
                  onActionClick={() => performWish(el.uuid)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
