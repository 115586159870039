import React from "react";
import Headline from "components/headline/Headline";
import WishTile from "components/wishTile/WishTile";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import { useSlc, RootState, action, useDsp } from "app/store";
import "swiper/css";
import CardTile from "components/cardTile/cardTile";
import CardIcon from "components/cardIcon/cardIcon";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
import TextLink from "components/textLink/textLink";
import CardContent from "components/cardContent/cardContent";
import { TotalCounter } from "elements";
import { path } from "ramda";

type props = {
  dashboard: any;
  performWish: any;
};

const NewWishlist = ({ dashboard, performWish }: props) => {
  const dispatch = useDsp();
  const wishCount: number = path(["data", "all_wishes_count"], dashboard) || 0;
  const wishList: any = path(["data", "list"], dashboard) || [];
  const user = useSlc((state: RootState) => state.user);

  return (
    <div className="new--wishlist">
      <div className="container">
        <Headline className="headline_lg new--wishlist__headline">
          Вишлист
        </Headline>

        <TotalCounter
          className="new--wishlist__counter"
          title="Всего вишей"
          quantity={wishCount}
        />

        <Swiper
          className="new--wishlist__list"
          modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView="auto"
          slideToClickedSlide
          navigation={{
            prevEl: ".new--wishlist__arrow--prev",
            nextEl: ".new--wishlist__arrow--next",
          }}
          pagination={{
            clickable: true,
            bulletActiveClass: "active",
            bulletClass: "swiper--dots__item",
            el: ".new--wishlist__dots",
            renderBullet(index, className) {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {wishList.slice(0, 10).map((el: any) => {
            const name = path(["author", "name"], el);
            const city: string = path(["author", "city", "name"], el) || "";

            return (
              <SwiperSlide key={el.uuid} className="wish--tile--slide">
                {user.uuid !== el.author.uuid && (
                  <WishTile
                    key={el.uuid}
                    className="wishlist-paginator__tile"
                    title={el.title}
                    avatar={el.author.image}
                    name={`${name}`}
                    location={city}
                    tags={[{ text: el.category.name, type: "" }]}
                    hashtags={el.tags}
                    // BUTTONS
                    btnDetailShow
                    btnActionShow
                    linkToProfile={`/user/${el.author.uuid}`}
                    // ACTIONS
                    onDetailClick={() => {
                      dispatch(
                        action.mWishDetailsDataSet({
                          uuid: el.uuid,
                          title: "Виш коллеги",
                          name: el.title,
                          description: el.description,
                          category: el.category.name,
                          city: el.city.name,
                          maxPerformers: el.max_performer_amount,
                          status: el.status,
                        })
                      );
                      dispatch(action.mWishDetailsShow());
                    }}
                    onActionClick={() => performWish(el.uuid)}
                  />
                )}

                {user.uuid === el.author.uuid && (
                  <WishTile
                    key={el.uuid}
                    className="wishlist-paginator__tile"
                    title={el.title}
                    avatar={el.author.image}
                    name={`${name}`}
                    location={city}
                    tags={[{ text: el.category.name, type: "" }]}
                    hashtags={el.tags}
                    // BUTTONS
                    linkToProfile={`/user/${el.author.uuid}`}
                  />
                )}
              </SwiperSlide>
            );
          })}
          <SwiperSlide className="wish--tile--slide wish--tile--slide_end">
            <CardTile autoheight={false}>
              <CardIcon>
                <IconStaf />
              </CardIcon>
              <CardContent
                title="Посмотри остальные желания коллег!"
                descr=""
              />

              <TextLink
                className="card--tile__link"
                arrow
                url="/wishlists"
                weight={500}
              >
                Перейти в доску желаний
              </TextLink>
            </CardTile>
          </SwiperSlide>

          <SwiperNavigation
            className="new--wishlist__navigation"
            arrowPrev="new--wishlist__arrow--prev"
            arrowNext="new--wishlist__arrow--next"
            dots="new--wishlist__dots"
          />
        </Swiper>
      </div>
    </div>
  );
};

export default NewWishlist;
