export const dtoFormikServerErr = (data: any) => {
  const result: any = {};
  if (!!data && typeof data === "object") {
    const keys = Object.keys(data);
    if (keys.length > 0)
      keys.map((key) => {
        // fix keys
        if (key === "personnel_number") result.prNumber = data[key];
        //
        else result[key] = data[key];
      });
  }
  return result;
};
