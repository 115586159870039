import React from "react";
import { Formik, FormLogin } from "components/form";
import { apiLogin } from "app/api";
import { schemas, values } from "app/utils/schemas";

export const WrapperLogin = () => {
  const apiSuccess = ({ data, status }: any) => {
    // console.log("API LOGIN SUCCESS", data);
    if (status === 200 && data.access && data.refresh) {
      localStorage.setItem("tokenRef", data.refresh);
      // localStorage.setItem("tokenAcc", data.access);
      document.location = "/";
    }
  };

  const apiError = (err: any) => {
    // console.log("API LOGIN ERROR", err);
  };

  return (
    <Formik
      initialValues={values.login}
      validationSchema={schemas.login}
      onSubmit={(params, { setErrors }) =>
        apiLogin(params, apiSuccess, apiError, setErrors)
      }
    >
      <FormLogin />
    </Formik>
  );
};
