import React, { FC, ReactNode, MouseEventHandler } from 'react'
import clsx from 'clsx'

type props = {
  className?: string,
  size?: string,
  children: ReactNode,
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ButtonText: FC<props> = (props) => {
  const classes = clsx('button--text', props.className, {
    'button--text_md': props.size === 'md'
  });

  return (
    <button
      className={classes}
      type='button'
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export default ButtonText;
