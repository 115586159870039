import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

type props = {
  className?: string,
  children?: ReactNode,
  autoheight?: boolean,
}

const CardTile: FC<props> = (props) => {
  const classes = clsx('card--tile', props.className, {
    'card--tile_autoheight': props.autoheight
  });

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

export default CardTile;
