import React, { useState, useEffect, useRef } from "react";
import { apiNotifyRead } from "app/api";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import iconNotifyInfo from "assets/images/icon-notify-info.svg";
import { path } from "ramda";

const notifyMessage = (type: string): { title: string; text: string } => {
  if (type === "wish_expiration")
    return {
      title: "Истекает срок исполнения виша",
      text: "К сожалению, срок исполнения твоего виша истекает.",
    };
  if (type === "wish_published")
    return {
      title: "Твой виш принят",
      text: "Твой виш принят. Теперь осталось подождать, пока твои коллеги откликнутся на него.",
    };
  if (type === "wish_rejected")
    return {
      title: "Твой виш отклонен",
      text: "К сожалению, твой виш не прошел модерацию. Для уточнения причин отклонения обратись в поддержку",
    };
  if (type === "wish_new_contract")
    return {
      title: "Твой виш взяли в работу",
      text: "Твой виш взят на исполнение коллегой, самое время обсудить все детали.",
    };
  if (type === "wish_contract_canceled")
    return {
      title: "Исполнитель отказался от виша",
      text: "К сожалению, твой коллега отказался от исполнения виша. Твое объявление теперь видно остальным коллегам.",
    };
  if (type === "wish_contract_committed")
    return {
      title: "Подтвердите исполнение виша",
      text: "Коллега исполнил твой виш. Перейди, чтобы принять или отклонить исполнение.",
    };
  if (type === "wish_contract_commitment_rejected")
    return {
      title: "Автор виша не принял твой отклик на исполнение",
      text: "Твой коллега не подтвердил исполнение виша",
    };
  if (type === "wish_contract_commitment_approved")
    return {
      title: "Автор виша принял твой отклик на исполнение",
      text: "Твой коллега подтвердил исполнение виша",
    };
  if (type === "wish_new_message")
    return {
      title: "Новое сообщение",
      text: "В чате появилось новое непрочитанное сообщение.",
    };
  return { title: "", text: "" };
};

export const WishNotify = ({ wishId }: any) => {
  console.log("WISH NOTIFY");
  const dispatch = useDsp();
  const [wishNotifyList, setWishNotifyList] = useState([]);
  const notifyList: any = useSlc((state: RootState) => state.notifyList) || [];

  const apiNotifyListError = (error: any) => {
    console.log(error);
  };

  useEffect(() => {
    const currWishNotifyList: any = notifyList.filter(
      (notifyItem: any) =>
        notifyItem.referenceable.uuid === wishId &&
        notifyItem.unread &&
        notifyItem.type !== "wish_new_message"
    );
    const readIds = currWishNotifyList.map((item: any) => item.id);
    if (readIds.length > 0) {
      setWishNotifyList(currWishNotifyList);
      apiNotifyRead(
        { ids: readIds },
        (data: any) => {
          const ids = path(["data"], data) || [];
          const oldList = store.getState().notifyList || [];
          if (ids.length > 0) {
            // UPDATE REDUX STORE
            const newList = oldList.map((nItem: any) => {
              if (ids.includes(nItem.id)) {
                const item = {
                  id: nItem.id,
                  referenceable: nItem.referenceable,
                  type: nItem.type,
                  referenceable_type: nItem.referenceable_type,
                  description: nItem.description,
                  unread: false,
                };
                return item;
              }
              return nItem;
            });
            dispatch(action.notifyListSet(newList));
            // UPDATE USE STORE
            setTimeout(() => {
              const newList = wishNotifyList.filter(
                (nItem: any) => !ids.includes(nItem.id)
              );
              setWishNotifyList(newList);
            }, 5000);
          }
        },
        apiNotifyListError
      );
    }
  }, [notifyList, wishId]);

  return (
    <div className="wish-notify__quick-notify-container">
      {wishNotifyList.length > 0 &&
        wishNotifyList.map((notifyItem: any, id: number) => {
          const notifyType: string = notifyItem.type || "";
          const notifyTitle: string = notifyMessage(notifyType).title || "";
          const notifyDescr: string = notifyMessage(notifyType).text || "";
          return (
            <div className="wish-notify__quick-notify" key={id}>
              <div className="wish-notify__quick-notify-wrapper">
                <img src={iconNotifyInfo} alt="" />
                <div>
                  <b>{notifyTitle}</b>
                  <span>{notifyDescr}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
