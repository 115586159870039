import React, { FC } from "react";
import { ModalBasic } from "components/modal/";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperWishDone } from "app/wrappers";

export const ModalWishDone: FC = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishDone);
  return (
    <ModalBasic
      show={!!uuid}
      onHide={() => dispatch(action.mWishDoneSet(""))}
      title={
        <span>
          Ты уверен (-а), что <br />
          исполнил желание?
        </span>
      }
    >
      <WrapperWishDone />
    </ModalBasic>
  );
};
