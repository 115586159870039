import React, { useState, useEffect } from "react";
import { store, action, useDsp } from "app/store";
import { Formik, FormFeedback } from "components/form";
import { apiFeedback, apiFeedbackTheme } from "app/api";
import { schemas } from "app/utils/schemas";

export const WrapperFeedback = () => {
  const dispatch = useDsp();
  const [themes, setThemes] = useState([]);
  const { user } = store.getState();

  // FEEDBACK
  const apiSuccess = ({ status }: any) => {
    // console.log("API FEEDBACK SUCCESS", status);
    if (status === 201) {
      dispatch(
        action.mInfoSetData({
          title: "Спасибо!",
          message: "Мы свяжемся с тобой в течение двух рабочих дней",
        })
      );
      dispatch(action.mInfoShow());
      dispatch(action.mFeedbackHide());
    }
  };

  const apiError = (err: any) => {
    // console.log("API FEEDBACK ERROR", err);
    dispatch(
      action.mInfoSetData({
        title: "Ошибка!",
        message: "Проверь интернет соединение или повтори позднее",
      })
    );
    dispatch(action.mInfoShow());
    dispatch(action.mFeedbackHide());
  };

  // FEEDBACK THEMES
  const apiFbThemesSuccess = ({ data, status }: any) => {
    if (status === 200 && data) {
      const array = data.map((el: any) => ({
        label: el.name,
        value: el.uuid,
      }));
      setThemes(array);
    }
  };

  const apiFbThemesError = (error: any) => {
    // console.log("API FEEDBACK THEMES ERROR", error);
  };

  useEffect(() => {
    apiFeedbackTheme(apiFbThemesSuccess, apiFbThemesError);
  }, []);

  return (
    <Formik
      initialValues={{
        name: user.name || "",
        email: user.email || "",
        theme: "",
        message: "",
      }}
      validationSchema={schemas.feedback}
      onSubmit={(params) => apiFeedback(params, apiSuccess, apiError)}
    >
      <FormFeedback themes={themes} disabled={user.isAuth} />
    </Formik>
  );
};
