import { store } from "app/store";

export const dtoProfile = (params: any) => {
  const { user }: any = store.getState();
  const obj: any = {
    name: user.name,
    description: params.description,
    city: params.city.value,
    date_birthday: params.date ? params.date : user.birthDay,
    // contact_phone: params.phone ? params.phone : user.phone,
    // contact_telegram: params.telega ? params.telega : user.telega,
  };

  if (params.phone) obj.contact_phone = params.phone;
  if (params.telega) obj.contact_telegram = params.telega;

  // console.log("PROFILE DTO", obj);
  return obj;
};
