import React, { FC } from "react";
import { Button } from "components/button";
import { Link } from "react-router-dom";
import { ReactComponent as Placeholder } from "assets/images/user-avatar-placeholder.svg";
import { ReactComponent as IconDelete } from "assets/images/icon-delete.svg";
import CardTag from "components/cardTag/cardTag";
import CardHashtag from "components/cardHashtag/cardHashtag";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { clsx } from "clsx";

type props = {
  className?: string;
  title: string;
  avatar?: string;
  name?: string;
  location?: string;
  onClick?: any;
  tags?: {
    text: string;
    type: string;
  }[];
  hashtags?: string[];
  // REASON
  rejectionReason?: null | string;
  // BUTTONS
  isRemoveble?: boolean;
  btnDetailShow?: boolean;
  btnActionShow?: boolean;
  btnDetailLink?: string;
  linkToProfile?: string;
  btnAcceptShow?: boolean;
  btnAcceptDisabledShow?: boolean;
  btnDoneShow?: boolean;
  // ACTIONS
  onDetailClick?: any;
  onDelete?: any;
  onActionClick?: any;
  onAcceptClick?: any;
  onDoneClick?: any;
};

const baseurl = process.env.REACT_APP_API_BASE_URL;

const WishTile = ({
  className,
  avatar,
  tags,
  hashtags,
  title,
  name,
  location,
  // REASON
  rejectionReason,
  // BUTTONS
  isRemoveble,
  btnDetailLink,
  btnDetailShow,
  btnActionShow,
  linkToProfile,
  btnAcceptShow,
  btnAcceptDisabledShow,
  btnDoneShow,
  // ACTIONS
  onDelete,
  onDetailClick,
  onActionClick,
  onAcceptClick,
  onDoneClick,
}: props) => (
  <div className={clsx("wish--tile", className)}>
    {isRemoveble && (
      <button
        className="btn-reset wish--tile__remove-button"
        type="button"
        onClick={() => onDelete()}
      >
        <IconDelete />
      </button>
    )}

    {/* STATUSES */}
    <div className="wish--tile__tags">
      {tags?.map(({ text, type }: any, idx: number) => {
        if (type === "rejected" && rejectionReason) {
          return (
            <CardTag
              key={idx}
              text={text}
              type={type}
              rejectionReason={rejectionReason}
            />
          );
        }
        return <CardTag key={idx} text={text} type={type} />;
      })}
    </div>

    {!linkToProfile && (
      <div className="wish--tile__user">
        {avatar !== "disabled" && (
          <div className="wish--tile__user--avatar">
            {/* {avatar ? <img src={baseurl + avatar} alt="" /> : <Placeholder />} */}
            {avatar ? <img src={avatar} alt="" /> : <Placeholder />}
          </div>
        )}
        <div className="wish--tile__user--name">{name}</div>
        <div className="wish--tile__user--location">{location}</div>
      </div>
    )}

    {linkToProfile && (
      <Link className="wish--tile__user" to={linkToProfile}>
        {avatar !== "disabled" && (
          <div className="wish--tile__user--avatar">
            {/* {avatar ? <img src={baseurl + avatar} alt="" /> : <Placeholder />} */}
            {avatar ? <img src={avatar} alt="" /> : <Placeholder />}
          </div>
        )}
        <div className="wish--tile__user--name">{name}</div>
        <div className="wish--tile__user--location">{location}</div>
      </Link>
    )}

    <div className="wish--tile__hashtags">
      {hashtags?.map((el, idx) => (
        <CardHashtag key={idx} text={el} />
      ))}
    </div>
    <div className="wish--tile__title">{title}</div>
    <div className="wish--tile__actions">
      {/* BUTTON  DETAIL LINK */}
      {btnDetailLink && (
        <Link className="button button_border" to={btnDetailLink}>
          Подробнее
        </Link>
      )}

      {btnDetailShow && (
        <Button type="button" border={true} onClick={() => onDetailClick()}>
          Подробнее
        </Button>
      )}

      {btnActionShow && (
        <Button type="button" onClick={() => onActionClick()}>
          Исполнить
        </Button>
      )}

      {btnAcceptShow && (
        <Button type="button" onClick={() => onAcceptClick()}>
          Принять виш
        </Button>
      )}

      {btnAcceptDisabledShow && (
        <Button type="button" disabled={true}>
          Принять виш
        </Button>
      )}

      {btnDoneShow && (
        <Button type="button" onClick={() => onDoneClick()}>
          Нажми, если исполнил
        </Button>
      )}
    </div>
  </div>
);

export default WishTile;
