import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { profileLinks } from "app/constants";
import { useDsp, action } from "app/store/index";

type props = {
  links: {
    title: string;
    route: string;
  }[];
};

export const SubNav: FC<props> = ({ links }) => {
  const currRoute = useLocation().pathname;
  const equalRoutr = currRoute.split("/").slice(0, 3).join("/");
  const dispatch = useDsp();
  const logout = () => {
    dispatch(
      action.userSet({
        isAuth: false,
        name: "",
        email: "",
        number: "",
        phone: "",
      })
    );
    // localStorage.removeItem("tokenAcc");
    localStorage.removeItem("tokenRef");
  };

  return (
    <nav className="subnav">
      <div className="subnav__container container">
        <ul className="subnav__list">
          {links.map(({ visible, route, pageReset, title, id }: any) => (
            <li className="subnav__item" key={id}>
              {visible && (
                <NavLink
                  className={
                    equalRoutr === route ? "subnav__link curr" : "subnav__link"
                  }
                  to={route}
                  onClick={() => {
                    if (pageReset) {
                      dispatch(
                        action.fWishPaginationSet({
                          page: 1,
                          count: 1,
                        })
                      );
                    }
                  }}
                >
                  {title}
                </NavLink>
              )}
            </li>
          ))}
        </ul>

        {profileLinks.some((link: any) => link.route === currRoute) && (
          <button
            className="btn-reset button-action-forward"
            type="button"
            onClick={() => logout()}
          >
            <span>Выход</span>
            <IconArrowRight />
          </button>
        )}
      </div>
    </nav>
  );
};
