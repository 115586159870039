import React from "react";
import { Formik, FormReg } from "components/form";
import { apiReg } from "app/api";
import { schemas, values } from "app/utils/schemas";
import { useNavigate } from "react-router-dom";
import { action, useDsp } from "app/store";
import { info } from "app/constants/messages";

export const WrapperReg = () => {
  const navigate = useNavigate();
  const dispatch = useDsp();

  const apiSuccess = ({ status }: any, email: string) => {
    // console.log("API REG SUCCESS");
    if (status === 201) {
      navigate("/sign-in");
      dispatch(
        action.mInfoSetData({
          title: info.reg.title,
          message: info.reg.message,
        })
      );
      // console.log("ACT EMAIL", email);
      dispatch(action.mInfoShow());
      dispatch(action.authVariantSet("activate"));
      dispatch(action.activationIsShowSet(false));
      dispatch(action.activatedResendSet(false));
      dispatch(action.activatedEmailSet(email));
    }
  };

  const apiError = (err: any) => {
    // console.log("API REG ERROR", err);
  };

  return (
    <Formik
      initialValues={values.regis}
      validationSchema={schemas.regis}
      onSubmit={(params, { setErrors }) => {
        apiReg(
          params,
          (data: any) => apiSuccess(data, params.email),
          apiError,
          setErrors
        );
      }}
    >
      <FormReg />
    </Formik>
  );
};
