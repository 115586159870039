import { createAction, createReducer } from "@reduxjs/toolkit";

// // SITY LIST
// const cityListSet = createAction<any>("userSet");
// const cityList = createReducer([], (builder) => {
//   builder.addCase(cityListSet, (state, action) => action.payload);
// });

// // FAQ DATA
// const faqDataSet = createAction<any>("faqDataSet");
// const faqData = createReducer([], (builder) => {
//   builder.addCase(faqDataSet, (state, action) => action.payload);
// });

// NOTIFY LIST
const notifyWishSet = createAction<any>("notifyWishSet");
const notifyWish = createReducer([], (builder) => {
  builder.addCase(notifyWishSet, (state, action) => action.payload);
});

const notifyListSet = createAction<any>("notifyListSet");
const notifyList = createReducer([], (builder) => {
  builder.addCase(notifyListSet, (state, action) => action.payload);
});

// // WISHPAGINATOR DATA
// const wishPaginatorDataSet = createAction<any>("notifyWishMessageSet");
// const wishPaginator = createReducer({}, (builder) => {
//   builder.addCase(wishPaginatorDataSet, (state, action) => action.payload);
// });

// // RELOAD MAIN
// const reloadMainSet = createAction<number>("reloadMainSet");
// const reloadMain = createReducer(0, (builder) => {
//   builder.addCase(reloadMainSet, (state, action) => action.payload);
// });

// // TINODE
// const tiNodeSet = createAction<any>("tiNodeSet");
// const tiNode = createReducer(0, (builder) => {
//   builder.addCase(tiNodeSet, (state, action) => action.payload);
// });

export const actionNotify = {
  // cityListSet,
  // faqDataSet,
  notifyWishSet,
  notifyListSet,
  // wishPaginatorDataSet,
  // reloadMainSet,
  // tiNodeSet,
};

export const reducerNotify = {
  // cityList,
  // faqData,
  notifyWish,
  notifyList,
  // wishPaginator,
  // reloadMain,
  // tiNode,
};
