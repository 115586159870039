import React from "react";
import RedirectTo from "components/RedirectTo/RedirectTo";
import type { RootState } from "app/store";
import { useSlc } from "app/store";
import { SectionProfile } from "blocks/profile";
import { SubNav } from "components/nav";
import { profileLinks } from "app/constants";

export const Profile = () => {
  const user = useSlc((state: RootState) => state.user);

  if (user.isAuth)
    return (
      <>
        <SubNav links={profileLinks} />
        <SectionProfile />;
      </>
    );
  return <RedirectTo path="/sign-in" />;
};
