import React, { FC } from "react";
import { ModalBasic } from "components/modal";
import { action, useDsp, useSlc, RootState } from "app/store";
import { WrapperFeedback } from "app/wrappers";

export const ModalFeedback: FC = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mFeedback)}
      onHide={() => dispatch(action.mFeedbackHide())}
      title="Обратная связь"
    >
      <WrapperFeedback />
    </ModalBasic>
  );
};
