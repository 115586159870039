import { createAction, createReducer } from "@reduxjs/toolkit";

const dataProfileWishesSet = createAction<any>("dataProfileWishesSet");

const dataProfilePrfsSet = createAction<any>("dataProfilePrfsSet");

const dataProfileWishes = createReducer([], (builder) => {
  builder.addCase(dataProfileWishesSet, (state, action) => action.payload);
});

const dataProfilePrfs = createReducer([], (builder) => {
  builder.addCase(dataProfilePrfsSet, (state, action) => action.payload);
});

export const actionProfile = {
  dataProfileWishesSet,
  dataProfilePrfsSet,
};

export const reducerProfile = {
  dataProfileWishes,
  dataProfilePrfs,
};
