import React, { FC } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'

type props = {
  path: string,
}

const RedirectTo: FC<props> = (props) => {
  let location = useLocation();
  return <Navigate to={props.path} state={{ from: location }} replace />;
}

export default RedirectTo;