import React from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { apiWishRefuse } from "app/api";
import { Button } from "components/button";
import { useNavigate } from "react-router-dom";

export const WrapperWishRefuse = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();

  const uuid = useSlc((state: RootState) => state.mWishRefuse);

  const apiSuccsess = ({ status }: any, uuid: string) => {
    console.log("API REFUSE WISH SUCCESS");
    if (status === 200) {
      dispatch(action.mWishRefuseSet(""));
      navigate("/profile/performers");
    }
  };

  const apiError = (err: any) => {
    console.log("API REFUSE WISH SUCCESS", err);
  };

  return (
    <div className="row mt-2">
      <div className="d-flex">
        <Button
          type="button"
          onClick={() =>
            apiWishRefuse(
              uuid,
              (data: any) => apiSuccsess(data, uuid),
              apiError
            )
          }
        >
          Подтвердить
        </Button>
        <Button
          type="button"
          white
          onClick={() => dispatch(action.mWishRefuseSet(""))}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
};
